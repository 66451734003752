import styled from "styled-components";

export const StyledFocusContainer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: left;
  align-items: center;

  background-color: ${({ theme }) => theme.white};

  font-weight: 500;

  padding: 5px 10px;
`;

export const StyledPrompt = styled.span``;

export const StyledGroup = styled.span`
  padding-left: 5px;
  padding-right: 5px;
`;

export const StyledFilter = styled.span`
  color: ${({ theme }) => theme.neutral.medium};
`;
