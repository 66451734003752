import { graphql } from "@apollo/client/react/hoc";
import useAuth from "~/src/auth/hooks/use-auth";
import UPDATE_SHIFT_STATE from "~/src/graphql/mutations/update-shift-state/update-shift-state.mutation";

export default graphql(UPDATE_SHIFT_STATE, {
  name: "updateShiftState",
  props: props => {
    const auth = useAuth();

    return {
      updateShiftState: (shiftId, newState, note) =>
        props.updateShiftState({
          variables: auth.addVals(UPDATE_SHIFT_STATE, {
            shiftId,
            newState,
            note
          })
        })
    };
  }
});
