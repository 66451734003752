import React, { createContext, useState } from "react";
import moment from "moment-timezone";

export const FocusShiftContext = createContext();

export const FocusShiftContextProvider = ({ children }) => {
  const [focusShift, setFocusShift] = useState(null);

  const [isVenueFilter, setIsVenueFilter] = useState(true);
  const [isRoleFilter, setIsRoleFilter] = useState(true);
  const [isAvailabilityFilter, setIsAvailabilityFilter] = useState(true);
  const [isTagsFilter, setIsTagsFilter] = useState(true);
  const [isNotExcludedFilter, setIsNotExcludedFilter] = useState(true);
  const [isNotUnsuitableFilter, setIsNotUnsuitableFilter] = useState(true);

  const ctx = {
    focusShift,
    focusShiftId: focusShift?.id,
    setFocusShift: (newShift, forceUpdate = false) => {
      if (forceUpdate || newShift?.id !== focusShift?.id) {
        setFocusShift(newShift);

        setIsVenueFilter(true);
        setIsRoleFilter(true);
        setIsAvailabilityFilter(true);
        setIsTagsFilter(true);
        setIsNotUnsuitableFilter(true);
        setIsNotExcludedFilter(true);
      }
    },

    focusFilters:
      focusShift != null
        ? {
            partnerIds: [focusShift.targetAccountId],
            // shiftTypes: [focusShift.type],
            roleIds: isRoleFilter ? [focusShift.roleRate.roleId] : [],
            availableOnDays: isAvailabilityFilter
              ? [new moment(focusShift.startTime).format("DDD").toUpperCase()]
              : [],
            venueIds: [],
            homeVenueIds: null,
            userIds: null,
            forVenueId: isVenueFilter ? focusShift.venue.id : null,
            notExcludedFromVenueId: isNotExcludedFilter
              ? focusShift.venue.id
              : null,
            excludeUnsuitable: isNotUnsuitableFilter
          }
        : null,

    isVenueFilter,
    setIsVenueFilter,
    isRoleFilter,
    setIsRoleFilter,
    isAvailabilityFilter,
    setIsAvailabilityFilter,
    isTagsFilter,
    setIsTagsFilter,
    isNotUnsuitableFilter,
    setIsNotUnsuitableFilter,
    isNotExcludedFilter,
    setIsNotExcludedFilter,

    clearFocusShift: () => {
      setFocusShift(null);

      setIsVenueFilter(true);
      setIsRoleFilter(true);
      setIsAvailabilityFilter(true);
      setIsTagsFilter(true);
      setIsNotUnsuitableFilter(true);
      setIsNotExcludedFilter(true);
    }
  };

  return (
    <FocusShiftContext.Provider value={ctx}>
      {children}
    </FocusShiftContext.Provider>
  );
};

/**
 * Options and filters for the single shift view, to avoid overloading the main component
 */
export const provideFocusShiftContext = Component => props =>
  FocusShiftContextProvider({ children: <Component {...props} /> });
