import styled from "styled-components";

interface ContainerProps {
  isSpaced: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: rows;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  margin: ${({ isSpaced }) => (isSpaced ? "5px" : "0")};
`;

interface ButtonProps {
  isHovering: boolean;
  hasText: boolean;
  isButton: boolean;
  isDisabled: boolean;
  size: number;
  backgroundColor: string;
}

export const Button = styled.div<ButtonProps>`
  display: inline-block;

  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};

  margin: ${({ isButton }) => (isButton ? "2px" : "0")};
  padding: 5px;

  border: solid 1px transparent;
  border-radius: 50%;

  ${({ theme, isHovering }) =>
    isHovering ? `border-color: ${theme.primary.main};` : ""}

  ${({ backgroundColor, isHovering }) =>
    isHovering ? `background-color: ${backgroundColor};` : ""}

  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "cursor")};
`;

interface TextProps {
  size: number;
  isDisabled: boolean;
}

export const Text = styled.div<TextProps>`
  color: ${({ isDisabled, theme }) =>
    isDisabled ? theme.neutral.main : theme.primary.main};

  display: block;
  padding: 5px;
  line-height: ${({ size }) => `${size}px`};
`;
