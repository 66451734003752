export const GET_FILTERS = isSelfProvider => {
  let filterList = {
    FILTERS: {
      createdAfterFilter: {
        name: "createdAfterFilter",
        displayName: "New Shifts Since",
        searchPlaceHolder: null,
        searchTerm: "",
        selectedItems: []
      },
      partnerFilter: {
        name: "partnerFilter",
        displayName: "Partners",
        searchPlaceHolder: "Search partner",
        searchTerm: "",
        selectedItems: []
      },
      roleFilter: {
        name: "roleFilter",
        displayName: "Roles",
        searchPlaceHolder: "Search role",
        searchTerm: "",
        selectedItems: []
      },
      venueFilter: {
        name: "venueFilter",
        displayName: "Venues",
        searchPlaceHolder: "Search venue",
        searchTerm: "",
        selectedItems: []
      },
      homeVenueFilter: {
        name: "homeVenueFilter",
        displayName: "Home Venues",
        searchPlaceHolder: "Search home venue",
        searchTerm: "",
        selectedItems: []
      },
      createdByFilter: {
        name: "createdByFilter",
        displayName: "Created By",
        searchPlaceHolder: "Search user",
        searchTerm: "",
        selectedItems: []
      },
      shiftStatusFilter: {
        name: "shiftStatusFilter",
        displayName: "Shift Status",
        searchPlaceHolder: null,
        searchTerm: "",
        selectedItems: []
      },
      shiftTypesFilter: {
        name: "shiftTypesFilter",
        displayName: "Shift Types",
        searchPlaceHolder: null,
        searchTerm: "",
        selectedItems: []
      },
      shiftSourcesFilter: {
        name: "shiftSourcesFilter",
        displayName: "Shift Sources",
        searchPlaceHolder: null,
        searchTerm: "",
        selectedItems: []
      },
      availabilityFilter: {
        name: "availabilityFilter",
        displayName: "Availability",
        searchPlaceHolder: null,
        searchTerm: "",
        selectedItems: []
      }
    },
    DROPDOWNS: {
      main: false,
      partnerFilter: false,
      roleFilter: false,
      venueFilter: false,
      homeVenueFilter: false,
      createdByFilter: false,
      shiftStatusFilter: false,
      shiftTypesFilter: false,
      shiftSourcesFilter: false,
      createdAfterFilter: false,
      availabilityFilter: false
    }
  };

  if (!isSelfProvider) {
    delete filterList["FILTERS"]["homeVenueFilter"];
    delete filterList["DROPDOWNS"]["homeVenueFilter"];
  }

  return filterList;
};

export const getSelectedIds = (filterName, filters) =>
  filters[filterName]?.selectedItems.map(item => item.value);

export const filterBySearchTerm = (array, searchTerm) =>
  array?.filter(partner =>
    partner.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

export const formatUsers = (users, userId) => {
  const usersWithoutSelf = users.filter(user => user.id !== userId.toString());
  const selfUser = users.filter(user => user.id === userId.toString())[0];

  const formattedUsers = usersWithoutSelf.map(user => ({
    value: user.id,
    label: `${user.firstName} ${user.lastName}`
  }));

  const formattedSelfUser = {
    value: selfUser.id,
    label: `Me (${selfUser.firstName})`
  };

  return [formattedSelfUser, ...formattedUsers];
};

export const clearFilteredOptions = (filters, setFilters, dropdownOptions) => {
  const availableRoles = filters.roleFilter.selectedItems?.filter(role =>
    dropdownOptions.roleFilter?.map(option => option.value).includes(role.value)
  );

  const availableVenues = filters.venueFilter.selectedItems?.filter(venue =>
    dropdownOptions.venueFilter
      ?.map(option => option.value)
      .includes(venue.value)
  );

  setFilters({
    ...filters,
    roleFilter: {
      ...filters.roleFilter,
      selectedItems: availableRoles
    },
    venueFilter: {
      ...filters.venueFilter,
      selectedItems: availableVenues
    }
  });
};

export const capitalize = str =>
  str
    .split(" ")
    .map(word => word[0].toUpperCase() + word.substring(1).toLowerCase())
    .join(" ");

export const isLastItem = (array, index) => array.length === index + 1;
