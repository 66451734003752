import ADD from "./assets/add-new.svg";
import ADD_MEMBER from "./assets/add-member.svg";
import ADD_SHIFT from "./assets/add-shift.svg";
import AMOUNT from "./assets/amount.svg";
import ARROW_DOWN from "./assets/arrow-down.svg";
import ARROW_LEFT from "./assets/arrow-left.svg";
import ARROW_RIGHT from "./assets/arrow-right.svg";
import BONUS from "./assets/bonus.svg";
import BRIEFING_TEMPLATE from "./assets/briefing-template.svg";
import CALENDAR from "./assets/calendar.svg";
import CAMERA from "./assets/camera-icon.svg";
import CHECK from "./assets/check.svg";
import CHECK_CIRCLE from "./assets/check-circle.svg";
import CHEVRON from "./assets/chevron_icon.svg";
import CLEAR from "./assets/clear.svg";
import CLOCK from "./assets/clock.svg";
import CLOCK_SCHEDULER from "./assets/clock-scheduler.svg";
import CLOSE from "./assets/close-new.svg";
import DELETE from "./assets/delete.svg";
import DONE from "./assets/done.svg";
import DOWNLOAD from "./assets/download.svg";
import EDIT from "./assets/edit.svg";
import EXIT from "./assets/exit.svg";
import FAVOURITE_HEART from "./assets/favourite-heart.svg";
import HISTORY from "./assets/history.svg";
import INFO from "./assets/info.svg";
import INVITES from "./assets/invitation-icon.svg";
import LINKED_SHIFTS from "./assets/linked-shifts.svg";
import LINK from "./assets/link.svg";
import LOADING from "./assets/loading.svg";
import LOCK from "./assets/lock.svg";
import MAGIC from "./assets/magic.svg";
import MESSAGES_CHAT from "./assets/messages-chat.svg";
import MORE from "./assets/more.svg";
import MY_SHIFTS from "./assets/my-shifts.svg";
import MY_TEAM from "./assets/my-team.svg";
import NO_SHOW from "./assets/no-show.svg";
import ORANGE_PIN from "./assets/orange-pin.svg";
import PARTNER from "./assets/partner.svg";
import PAPER_AIRPLANE from "./assets/paper-aeroplane.svg";
import PLAY from "./assets/play.svg";
import PLUS from "./assets/plus-icon.svg";
import POOLS from "./assets/pools.svg";
import REFRESH from "./assets/refresh.svg";
import REPORT from "./assets/report.svg";
import ROTA_LOGO from "./assets/rota-logo.svg";
import SEARCH from "./assets/search.svg";
import SHIFTS from "./assets/shifts.svg";
import STAR from "./assets/star.svg";
import STAR_FILLED from "./assets/star-filled.svg";
import TAG from "./assets/tag.svg";
import UNIFORM_TEMPLATE from "./assets/uniform template_on.svg";
import UPLOAD from "./assets/upload.svg";
import VENUE_TEMPLATE from "./assets/venue-template.svg";
import VISIBLE from "./assets/visible.svg";
import SELF_MANAGED from "./assets/self-managed.svg";
import UNCHECK from "./assets/uncheck.svg";
import UPLOADS from "./assets/uploads.svg";
import BRIEFCASE from "./assets/briefcase.svg";

export default {
  ADD_MEMBER,
  ADD_SHIFT,
  ADD,
  AMOUNT,
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  BONUS,
  BRIEFING_TEMPLATE,
  CALENDAR,
  CAMERA,
  CHECK,
  CHECK_CIRCLE,
  CHEVRON,
  CLEAR,
  CLOCK,
  CLOCK_SCHEDULER,
  CLOSE,
  DELETE,
  DONE,
  DOWNLOAD,
  EDIT,
  EXIT,
  FAVOURITE_HEART,
  HISTORY,
  INFO,
  INVITES,
  LINKED_SHIFTS,
  LINK,
  LOADING,
  LOCK,
  MAGIC,
  MESSAGES_CHAT,
  MORE,
  MY_SHIFTS,
  MY_TEAM,
  NO_SHOW,
  ORANGE_PIN,
  PARTNER,
  PAPER_AIRPLANE,
  PLAY,
  PLUS,
  POOLS,
  REFRESH,
  REPORT,
  ROTA_LOGO,
  SEARCH,
  SELF_MANAGED,
  SHIFTS,
  STAR_FILLED,
  STAR,
  TAG,
  UNIFORM_TEMPLATE,
  UPLOAD,
  VENUE_TEMPLATE,
  VISIBLE,
  UNCHECK,
  UPLOADS,
  BRIEFCASE
};
