import styled from "styled-components";

export const StyledInfoBox = styled.div`
  margin-top: 3px;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  background-color: #A3CAFF;
  border-radius: 6px;

  & a {
    color: white;
    font-weight: 800;
  }
}`;
