import { gql, useQuery } from "@apollo/client";
import moment from "moment-timezone";

export const GET_SCHEDULES = gql`
  query getMemberBusy($startTime: Date!, $endTime: Date!, $memberIds: [ID]!) {
    account {
      id
      memberBusy(
        startTime: $startTime
        endTime: $endTime
        memberIds: $memberIds
      ) {
        memberId
        entries {
          date
          blocks {
            shiftId
            bookingId
            startTime
            endTime
          }
        }
      }
    }
  }
`;

export const useMemberBusy = ({ startTime, endTime, memberIds }) => {
  const { data, loading } = useQuery(GET_SCHEDULES, {
    variables: {
      memberIds,
      startTime: moment(startTime)
        .local()
        .format(),
      endTime: moment(endTime)
        .endOf("day")
        .local()
        .format()
    },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip: memberIds === undefined,
    onError: error => {
      throw error;
    }
  });

  return {
    busyLoading: loading,
    busyData: data?.account?.memberBusy
  };
};
