import { gql } from "@apollo/client";

export const GET_SHIFT_BY_ID = gql`
  query getShiftById($id: ID!) {
    account {
      shift(id: $id) {
        id
        finalisedAt
        openedAt
        cancelledAt
      }
    }
  }
`;
