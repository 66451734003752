import React, { memo, useState, useEffect } from "react";
import {
  StyledAvatarCardContainer,
  StyledAvatarCard,
  StyledAvatarCardText
} from "./avatar-card.styles";
import HoursWorked from "../HoursWorked/hours-worked";
import { RotaAvatar } from "@teamrota/rota-design";

const AvatarCard = ({
  member,
  hoursCompleted,
  shiftsWorked,
  isEmptyCell,
  isProviderScheduler,
  openShiftModal,
  memberIndex
}) => {
  const { firstName, lastName, photo, isLimitedHours, limitedHours } = member;
  const [isLoadedPhoto, setIsLoadedPhoto] = useState(false);

  // Start with the default photo for everyone, then load the correct file
  // for members with real, reachable Cloudinary photos
  // Some members have a cloudinary url, but the file is missing and request returns a 404,
  // so they stay with the default
  useEffect(() => {
    // Once valid photo is established, no need to recheck during this session
    if (isLoadedPhoto) {
      return;
    }

    const validPhoto = async () => {
      try {
        const status = await fetch(photo, {}).then(res => res.status);

        if (status === 200) {
          setIsLoadedPhoto(true);
        }
      } catch (e) {
        setIsLoadedPhoto(false);
      }
    };

    validPhoto();
  }, []);

  return (
    <StyledAvatarCardContainer onClick={() => openShiftModal(memberIndex)}>
      {!isEmptyCell && (
        <RotaAvatar size="normal" src={isLoadedPhoto ? photo : false} />
      )}
      <StyledAvatarCard>
        <StyledAvatarCardText>
          {firstName} {lastName}
        </StyledAvatarCardText>
        {isProviderScheduler && (
          <>
            <HoursWorked
              isLimitedHours={isLimitedHours}
              limitedHours={limitedHours}
              hoursCompleted={hoursCompleted}
              shiftsWorked={shiftsWorked}
            />
          </>
        )}
      </StyledAvatarCard>
    </StyledAvatarCardContainer>
  );
};

export default memo(AvatarCard);
