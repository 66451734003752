import React, { useState, useEffect, useContext } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { iconNew, RotaButton } from "@teamrota/rota-design";

import DateHeaders from "~/src/containers/scheduler/SchedulerGrid/DateHeaders/date-headers";
import CellRow from "~/src/containers/scheduler/SchedulerGrid/CellRow/cell-row";
import SearchInput from "~/src/containers/scheduler/SearchInput/search-input";
import AvatarCard from "~/src/containers/scheduler/AvatarCard/avatar-card";
import OpenShiftTableRow from "~/src/containers/scheduler/OpenShifts/OpenShiftTableRow/table-row";
import ViewEditMember from "~/src/containers/modals/view-edit-member";
import useAssignMemberToShift from "~/src/graphql/mutations/assign-member-to-shift";
import {
  StyledScheduleHead,
  StyledScheduleBody,
  StyledScheduleRow,
  StyledButtonWrapper,
  StyledGridHeader,
  StyledDay,
  StyledIconContainer,
  StyledShiftFocus
} from "~/src/containers/scheduler/SchedulerGrid/scheduler-grid.styles";

import { countDraftShifts, setDraftShiftIds } from "../reducer";
import FooterLegend from "../FooterLegend";

import { FocusShiftContext } from "../../provide-schedule/focus-shift-context";
import FocusChips from "../OpenShifts/OpenShiftTableRow/FocusChips/focus-chips";
import FocusActions from "../OpenShifts/OpenShiftTableRow/FocusActions/focus-actions";

const { Sun, Moon } = iconNew;

const SchedulerGrid = ({
  schedule,
  focusSchedule,
  busyByMember,
  startTime,
  startDate,
  onPressShiftCell,
  selectedDayIndex,
  setSelectedDayIndex,
  onPressGridCell,
  setHomeVenueIds,
  setRoleIds,
  setSortBy,
  setVenueIds,
  setSearchTerm,
  searchTerm,
  sortBy,
  countDraftShifts,
  setDraftShiftIds,
  totalMembersCount,
  loadMore,
  isProviderScheduler,
  isMemberAvailabilityEnabled
}) => {
  const { focusShift } = useContext(FocusShiftContext);
  const focusScheduledMembers = focusSchedule?.scheduledMembers || [];

  const {
    assignMemberToShift,
    loading: assignMemberLoading
  } = useAssignMemberToShift();

  const [isOpen, setIsOpen] = useState(false);
  const [modalId, setModalId] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const isDailyView = selectedDayIndex !== null || focusShift !== null;
  const isWeeklyView = !isDailyView;

  const openShiftModal = i => {
    setModalId(i);
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    loadMore({
      onFinish: () => {
        setIsLoadingMore(false);
      }
    });
  };

  useEffect(() => {
    countDraftShifts(schedule?.draftShiftIds?.length);
    setDraftShiftIds(schedule?.draftShiftIds);
  }, [schedule?.draftShiftIds]);

  const handlePressAvailabilityBadge = columnIndex => {
    setSelectedDayIndex(columnIndex);
  };

  return (
    <>
      <StyledScheduleHead>
        {!isProviderScheduler && (
          <SearchInput
            schedule={schedule}
            setHomeVenueIds={setHomeVenueIds}
            setRoleIds={setRoleIds}
            setSortBy={setSortBy}
            sortBy={sortBy}
            setVenueIds={setVenueIds}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
          />
        )}

        <DateHeaders
          isDailyView={isDailyView}
          startTime={startTime}
          selectedDayIndex={selectedDayIndex}
          setSelectedDayIndex={setSelectedDayIndex}
          isProviderScheduler={isProviderScheduler}
        />
      </StyledScheduleHead>

      <OpenShiftTableRow
        selectedDayIndex={selectedDayIndex}
        setSelectedDayIndex={setSelectedDayIndex}
        schedule={schedule}
        startDate={startDate}
        onPressGridCell={onPressGridCell}
        assignMemberLoading={assignMemberLoading}
      />

      <StyledScheduleBody>
        {isDailyView && (
          <StyledGridHeader>
            <StyledDay>
              {moment(schedule?.days?.[selectedDayIndex])?.format(
                "dddd Do MMMM"
              )}
            </StyledDay>
            {focusShift === null && (
              <>
                <StyledIconContainer>
                  <Sun />
                </StyledIconContainer>
                <StyledIconContainer>
                  <Moon />
                </StyledIconContainer>
              </>
            )}
          </StyledGridHeader>
        )}

        <StyledShiftFocus isShown={focusShift !== null}>
          {focusShift !== null && (
            <>
              <FocusChips />
              <FocusActions onShiftDetails={onPressShiftCell} />
            </>
          )}
        </StyledShiftFocus>

        {isDailyView && focusShift && (
          <StyledGridHeader>
            <StyledDay>&nbsp;</StyledDay>
            <StyledIconContainer>
              <Sun />
            </StyledIconContainer>
            <StyledIconContainer>
              <Moon />
            </StyledIconContainer>
          </StyledGridHeader>
        )}

        {focusScheduledMembers?.length > 0 &&
          focusScheduledMembers?.map((scheduledMember, i) => (
            <StyledScheduleRow key={scheduledMember?.member.id}>
              <AvatarCard
                openShiftModal={openShiftModal}
                memberIndex={i}
                member={scheduledMember?.member}
                hoursCompleted={scheduledMember?.hoursCompleted}
                shiftsWorked={scheduledMember?.shiftsWorked}
                isProviderScheduler={isProviderScheduler}
              />

              <CellRow
                scheduledMember={scheduledMember}
                busyMember={busyByMember?.[scheduledMember?.memberId] ?? {}}
                startDate={startDate}
                schedule={focusSchedule}
                startTime={startTime}
                onPressShiftCell={onPressShiftCell}
                onPressGridCell={onPressGridCell}
                onPressAvailabilityBadge={handlePressAvailabilityBadge}
                isMemberAvailabilityEnabled={isMemberAvailabilityEnabled}
                isWeeklyView={isWeeklyView}
                isDailyView={isDailyView}
                selectedDayIndex={selectedDayIndex}
                assignMemberToShift={assignMemberToShift}
              />
            </StyledScheduleRow>
          ))}

        {totalMembersCount > focusScheduledMembers?.length && (
          <StyledButtonWrapper>
            <RotaButton
              onClick={handleLoadMore}
              isLoading={isLoadingMore}
              size={"medium"}
            >
              Load More
            </RotaButton>
          </StyledButtonWrapper>
        )}

        {totalMembersCount === 0 && (
          <StyledButtonWrapper>
            No suitable members found.
            <br />
            Try removing a filter.
          </StyledButtonWrapper>
        )}
      </StyledScheduleBody>
      <FooterLegend />

      <ViewEditMember
        isOpen={isOpen}
        memberId={focusScheduledMembers?.[modalId]?.member?.id}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
const mapStateToProps = s => ({
  showCancelledShifts: s.scheduler?.showCancelledShifts,
  countOfDraftShifts: s.scheduler?.countOfDraftShifts,
  draftShiftIds: s.scheduler?.draftShiftIds
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      countDraftShifts,
      setDraftShiftIds
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerGrid);
