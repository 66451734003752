import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import moment from "moment-timezone";

import { ZonedDate } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";
import { ThemeProvider } from "@teamrota/rota-design/dist/components/theme-provider";
import { ToastProvider } from "@teamrota/rota-design/dist/components/toast";

import { ACCOUNT_TIMEZONE } from "./consts";

import useAuth from "~/src/auth/hooks/use-auth";

import Authed from "~/src/auth/containers/authed";
import ChangePassword from "~/src/auth/components/change-password";
import Logout from "~/src/auth/components/logout";

import AuthorizedRoute from "./components/routing/authorized-route";
import RootRoute from "./components/routing/root-route";

import GlobalStyle from "./styles/GlobalStyle";
import { NavigationWatcher } from "./nav";

import GlobalModals from "./containers/global-modals";
import Dashboard from "./containers/dashboard";
import NotFound from "./components/not-found";
import BriefingTemplates from "./containers/templates/containers/briefing-templates";
import UniformTemplates from "./containers/templates/containers/uniform-templates";
import VenueTemplates from "./containers/templates/containers/venue-templates";
import MyStaff from "./containers/my-staff";
import Timesheets from "./containers/timesheets";
import TimesheetsNew from "./containers/timesheets-new";
import RequesterCalendar from "./containers/requester-calendar";
import Pools from "./containers/pools";
import Provide from "./containers/provide";
import Roles from "./containers/roles";
import MemberTypes from "./containers/member-types";
import Partners from "./containers/partners";
import Users from "./containers/users";
import NotificationsHistory from "./containers/notifications-history";
import Reports from "./containers/reports";
import SendNotifications from "./containers/notifications-send";
import ServiceAreas from "./containers/service-areas";
import Settings from "./containers/settings-page/settings-page-content/settings-page-content";
import PolicyPage from "./containers/policy-page";
import Scheduler from "./containers/scheduler/scheduler";
import BusyMembersFiles from "./containers/BusyMembersFiles";
import ProfileProvider from "./containers/profile-loader";
import ProvideSchedule from "./containers/provide-schedule";
import AdminDashboard from "./containers/admin-dashboard";
import Payroll from "./containers/payroll";
import PayRate from "./containers/payroll-rate";
import Calculations from "./containers/payroll-calculations";
import PayScale from "./containers/payroll-scale";
import Rules from "./containers/payroll-rules";
import Breaks from "./containers/payroll-breaks";

moment.tz.setDefault(ACCOUNT_TIMEZONE);
ZonedDate.setDefault(ACCOUNT_TIMEZONE);

TagManager.initialize({ gtmId: "GTM-5JZZ6B9" });

ReactGA.initialize("UA-117179107-1", {
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

const App = () => {
  let { pathname } = useLocation();
  const auth = useAuth();

  useEffect(() => {
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }, [pathname]);

  useEffect(() => {
    if (window._kiq && auth.authed) {
      window._kiq.push(["identify", auth.info?.email]);
    }
  }, [auth.authed, auth.info?.email]);

  return (
    <Switch>
      <Route path="/">
        <ThemeProvider>
          <GlobalStyle />
          <Authed>
            <ToastProvider>
              <ProfileProvider>
                <GlobalModals />
                <NavigationWatcher>
                  <Dashboard>
                    <Switch>
                      <RootRoute exact path="/" />
                      <RootRoute exact path="/Login" />

                      <AuthorizedRoute
                        exact
                        path="/request"
                        component={RequesterCalendar}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/request/add"
                        component={RequesterCalendar}
                        role={Role.REQUESTER}
                        extraProps={{ isAddShift: true }}
                      />

                      <AuthorizedRoute
                        exact
                        path="/request/briefing-templates"
                        component={BriefingTemplates}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/request/uniform-templates"
                        component={UniformTemplates}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/request/venue-templates"
                        component={VenueTemplates}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/request/pools"
                        component={Pools}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/my-staff"
                        component={MyStaff}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/my-staff/roles"
                        component={Roles}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/my-staff/member-types"
                        component={MemberTypes}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/my-staff/unavailability-files"
                        component={BusyMembersFiles}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/provide"
                        component={Provide}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/provide/notifications"
                        component={SendNotifications}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/provide/notifications/history"
                        component={NotificationsHistory}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/timesheets"
                        component={Timesheets}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/timesheets-new"
                        component={TimesheetsNew}
                        role={Role.REQUESTER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll"
                        component={Payroll}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll/pay-rate/:id?"
                        component={PayRate}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll/calculations/:id?"
                        component={Calculations}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll/pay-scales/:id?"
                        component={PayScale}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll/rules/:id?"
                        component={Rules}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/payroll/breaks/:id?"
                        component={Breaks}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/schedule"
                        component={Scheduler}
                        role={Role.SCHEDULER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/provide-schedule"
                        component={ProvideSchedule}
                        role={Role.PROVIDER_SCHEDULER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/settings"
                        component={Settings}
                      />

                      <AuthorizedRoute
                        exact
                        path="/settings/users"
                        component={Users}
                      />

                      <AuthorizedRoute
                        exact
                        path="/settings/partners"
                        component={Partners}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/settings/service-areas"
                        component={ServiceAreas}
                        role={Role.PROVIDER}
                      />

                      <AuthorizedRoute
                        exact
                        path="/settings/reports"
                        component={Reports}
                        role={Role.REPORTS_PAYROLL}
                      />

                      <AuthorizedRoute
                        path="/admin-dashboard"
                        component={AdminDashboard}
                        role={Role.ROTA_ADMIN}
                      />

                      <Route exact path="/logout" component={Logout} />

                      <Route
                        exact
                        path="/change-password"
                        component={ChangePassword}
                      />

                      <Route exact path="/policy" component={PolicyPage} />
                      <Route path="*" component={NotFound} />
                    </Switch>
                  </Dashboard>
                </NavigationWatcher>
              </ProfileProvider>
            </ToastProvider>
          </Authed>
        </ThemeProvider>
      </Route>
    </Switch>
  );
};

export default App;
