import React, { useState } from "react";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { flow, set, some, update, values } from "lodash/fp";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Role } from "@teamrota/authlib";
import {
  RotaModal,
  RotaButtonNew,
  RotaSwitch,
  iconNew,
  RotaCheckbox
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import { ALLOW_TAGS, SHIFT_TYPES } from "~/src/consts";
import Icon from "~/src/components/icon";
import Button from "~/src/components/button";
import LabelValue from "~/src/components/label-value";
import DatePicker from "~/src/components/form-components/date-picker";
import TextInput from "~/src/components/form-components/text-input";
import FormSelect from "~/src/components/form-components/select";
import TextArea from "~/src/components/form-components/textarea";
import OpenShiftButton from "./components/open-shift-button";
import RequestZonesPanel from "~/src/components/RequestZonesPanel";
import NewBonusRewardComponent from "~/src/components/bonus-reward/bonus-component";
import { GET_ROLE_RATE } from "~/src/components/RateBreakdown/graphql";
import TagsContainer from "~/src/containers/tags";
import { MODAL_TYPES } from "~/src/containers/global-modals";
import HasRole, { HasAnyRole } from "~/src/containers/has-role";
import { GET_MEMBERS_OVER_LIMIT } from "~/src/containers/modals/view-edit-shift/shift-details/graphql";
import ShiftUpdateMemberConflict from "~/src/containers/modals/shift-update-member-conflict";
import * as reducerActions from "~/src/containers/requester-calendar/reducer";
import handleEditsWithinCancellationPolicyPeriod from "~/src/containers/requester-calendar/shift-modal/handle-edits-within-cancellation-policy-period";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { SurgeString, surgeRoleOptions } from "~/src/services/surge-pricing";
import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";
import { getTotalCharge, getPolicyQueryParams } from "~/src/utils/formatting";
import {
  validateVenue,
  validateBriefing,
  validateShiftStartEnd,
  getHours,
  getIsPolicyApplied,
  validateIdentifier
} from "~/src/containers/requester-calendar/utils";
import { useCurrency } from "~/src/containers/profile-loader";

import {
  FormWrapper,
  FieldWrapper,
  FixedInputWrapper,
  DynamicInputWrapper,
  Heading,
  ButtonFooter,
  PricingWrapper,
  WarningsWrapper,
  ManageShiftText,
  SmallText,
  FlexItem,
  ShiftDetailsWrapper,
  TagsWrapper,
  TitleWrapper,
  Title,
  Text,
  StyledModalContainer,
  StyledModalButtons,
  StyledIconWrapper,
  DayOrNightShiftWrapper
} from "./edit-shift.styles";

const LABEL_WIDTH = 60;
const { Moon, Sun } = iconNew;

const EditShift = ({
  partners,
  shiftFormState,
  initialShift,
  roleRates,
  isVisible,
  onFormUpdate,
  onUpdateUniform,
  uniformOptions,
  briefingOptions,
  venueOptions,
  roleRateOptions,
  onBack,
  onSave,
  policy,
  isSaving,
  warningFields,
  isCancellingShift,
  isOpeningShift,
  onCancelShift,
  onOpenShift,
  shiftOpened,
  targetAccountId,
  bonuses,
  addBonusWithData,
  removeBonus,
  updateBonusType,
  updateBonusAmount,
  accountId,
  roleOptions,
  updateTotalBonus,
  alreadyExistingBonuses,
  shiftIndex,
  onDeleteDraftShift,
  isProviderScheduler,
  isShiftCancelled,
  subvenueOptions,
  loadingSubvenue
}) => {
  const auth = useAuth();

  if (!isVisible) return null;

  const shift = shiftFormState?.shifts?.[0];

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [bonusData, setBonusData] = useState();
  const [warning, setWarning] = useState(null);
  const [startTime, setStartTime] = useState(shift?.startTime);
  const [endTime, setEndTime] = useState(shift?.endTime);

  const { taxRate } = useCurrency();

  const { data } = useQuery(GET_ROLE_RATE, {
    variables: {
      roleRateId: shift?.roleRateId
    },
    skip: !shift?.roleRateId,
    fetchPolicy: "network-only"
  });

  const [getMembersOverLimit] = useLazyQuery(GET_MEMBERS_OVER_LIMIT, {
    fetchPolicy: "network-only",
    onError: error => {
      errorModal(error);
    },
    onCompleted: async data => {
      if (data?.shiftMembersOverLimit?.length > 0) {
        setWarning({
          members: data?.shiftMembersOverLimit,
          callback: onSave
        });
      } else {
        onSave();
      }
    }
  });

  const handleOnSave = async () => {
    await getMembersOverLimit({
      variables: {
        shiftId: shift.id,
        dates: { startTime, endTime }
      }
    });
  };

  const handleTimeUpdate = value => {
    setStartTime(value?.start);
    setEndTime(value?.end);

    return update(
      "shifts[0]",
      existingShift => ({
        ...existingShift,
        startTime: value?.start,
        endTime: value?.end
      }),
      shiftFormState
    );
  };

  const partnerDropdownValues = partners?.map(p => ({
    value: p.id,
    label: p.accountName
  }));

  const isUncalculatedRate = data?.account?.roleRateById?.isUncalculatedRate;

  const selectedAccountId = initialShift?.sourceAccount?.id;

  const onUpdate = prop => (value, parser = v => v) => {
    onFormUpdate(set(`shifts[0].${prop}`, parser(value), shiftFormState));
    if (prop === "uniformTemplateId") {
      onUpdateUniform(shift?.id);
    }

    if (prop === "venueId") {
      onFormUpdate(
        flow(
          set(`shifts[0].${prop}`, parser(value)),
          set(`shifts[0].subvenueId`, null)
        )(shiftFormState)
      );
    }
  };

  const onBriefingUpdate = (briefing, label) => {
    onFormUpdate(
      flow(
        set(`shifts[0].privates.fixedLabel`, label),
        set(`shifts[0].briefing`, briefing)
      )(shiftFormState)
    );
  };

  const errors = {
    times: !!validateShiftStartEnd(shift?.startTime, shift?.endTime),
    venue: !!validateVenue(shift?.venueId),
    briefing: !!validateBriefing(shift?.briefing),
    myId: !!validateIdentifier(accountId, targetAccountId, shift?.identifier)
  };

  const shiftTotal =
    auth.hasRole(Role.SHIFTS_COST) && !isProviderScheduler
      ? getTotalCharge({
          roleRateId: shift.roleRateId,
          numberRequested: shift.numberRequested,
          endTime: shift.endTime,
          startTime: shift.startTime,
          roleRates: roleRates,
          shouldIncludeVAT: false,
          policy: policy,
          bonusAmount: initialShift.totalCostBonusesAndRewards,
          bonusType: "Bonus",
          shouldNotAddBonusToTotal: true,
          targetAccountId: targetAccountId,
          taxRate
        })
      : null;

  const defaultPolicyMessage = getIsPolicyApplied(policy, shift)
    ? `The shift length is less than ${getHours(shift)} ` +
      `hours so ${policy?.minimumShiftLength} hours minimum will be charged in accordance with our`
    : "";

  const editPolicyMessage = handleEditsWithinCancellationPolicyPeriod(
    {
      ...shift,
      originalStartTime: shift?.originalStartTime || initialShift?.startTime,
      originalEndTime: shift?.originalEndTime || initialShift?.endTime
    },
    policy
  );

  const getCurrentBonuses = () => {
    const currentBonuses = bonuses?.filter(
      bonus => bonus?.shiftIndex === shiftIndex
    );
    return currentBonuses?.[0]?.items || [];
  };

  const onAddBonus = ({ type, amount, withConfirm = true }) => {
    if (withConfirm) {
      setIsAlertOpen(true);
      setBonusData({ shiftIndex, type, amount });
    } else {
      addBonusWithData({ shiftIndex, type, amount });
    }
  };

  const handleAlertClose = () => {
    setBonusData();
    setIsAlertOpen(false);
  };

  const handleAlertConfirm = () => {
    if (bonusData) {
      addBonusWithData(bonusData);
      setBonusData();
      setIsAlertOpen(false);
    }
  };

  const onToggleShiftType = e => {
    const type = e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT;
    onFormUpdate(flow(set(`shifts[0].type`, type))(shiftFormState));
  };

  const updatedRoleOptions = surgeRoleOptions({
    startTime: shift?.startTime,
    targetAccountId: targetAccountId,
    roleOptions: roleOptions
  });

  const index = shiftFormState?.isLinkedShifts
    ? 0
    : shiftFormState.shiftOpenIndex;

  return (
    <div>
      <div>
        <Button
          onClick={onBack}
          iconName={Button.iconNames.ARROW_LEFT}
          isGrey
          isXSmall
          isIconOnly
        />
      </div>

      <Heading>Edit shift</Heading>

      <div>
        {!isProviderScheduler && (
          <HasAnyRole roles={[Role.SHIFTS_COST, Role.SHIFTS_PAY]}>
            <NewBonusRewardComponent
              roleOptions={updatedRoleOptions}
              formData={shift}
              onAddBonus={onAddBonus}
              onUpdateBonusType={(index, type) =>
                updateBonusType({
                  index,
                  type,
                  shiftIndex
                })
              }
              onDeleteBonus={index =>
                removeBonus({ index, shiftIndex: shiftIndex })
              }
              onUpdateBonusAmount={(index, amount) =>
                updateBonusAmount({
                  index,
                  amount,
                  shiftIndex
                })
              }
              onUpdateBonusTotal={totalBonus => {
                updateTotalBonus({
                  totalBonus,
                  shiftIndex
                });
              }}
              bonuses={getCurrentBonuses()}
              alreadyExistingBonuses={alreadyExistingBonuses}
            />
          </HasAnyRole>
        )}
      </div>

      <FormWrapper>
        <ShiftDetailsWrapper>
          <FieldWrapper>
            <DayOrNightShiftWrapper>
              <span>Day or night shift</span>
              <RotaSwitch
                iconOn={<Sun />}
                iconOff={<Moon />}
                checked={shift.type === SHIFT_TYPES.DAY}
                onChange={onToggleShiftType}
              />
            </DayOrNightShiftWrapper>
            <LabelValue labelWidth={LABEL_WIDTH} label="I need">
              <FixedInputWrapper width={60}>
                <TextInput
                  value={shift?.numberRequested || 1}
                  isSmall
                  min={1}
                  onChangeValue={onUpdate("numberRequested", v =>
                    parseInt(v, 10)
                  )}
                  type="number"
                />
              </FixedInputWrapper>
              <DynamicInputWrapper>
                <FormSelect
                  isDisabled
                  value={shift?.roleRateId}
                  options={roleRateOptions}
                  placeholder="Role"
                  isSmall
                  onChange={onUpdate("roleRateId")}
                />
              </DynamicInputWrapper>
            </LabelValue>
          </FieldWrapper>
          {isProviderScheduler && (
            <FieldWrapper isRight>
              <LabelValue labelWidth={LABEL_WIDTH} label="for">
                <DynamicInputWrapper>
                  <FormSelect
                    isDisabled
                    value={targetAccountId}
                    options={partnerDropdownValues}
                    placeholder="Partner"
                    isSmall
                    onChange={onUpdate("partnerId")}
                  />
                </DynamicInputWrapper>
              </LabelValue>
            </FieldWrapper>
          )}
        </ShiftDetailsWrapper>

        <FieldWrapper>
          <LabelValue labelWidth={LABEL_WIDTH} label="When">
            <DatePicker
              isDisabled={moment().isAfter(shift?.endTime)}
              shouldNotSelectPastDate
              isSmall
              isError={errors?.times}
              start={shift?.startTime}
              end={shift?.endTime}
              onChange={value => onFormUpdate(handleTimeUpdate(value))}
            />
          </LabelValue>
        </FieldWrapper>

        {ALLOW_TAGS.ON_SHIFTS && (
          <TagsWrapper>
            <LabelValue isNoGutter labelWidth={LABEL_WIDTH} label="Tags">
              <TagsWrapper isInnerWrapper>
                <TagsContainer
                  addedTags={initialShift?.tags || []}
                  roleRateId={shift?.roleRateId}
                  accountId={accountId}
                  readOnly
                  emptyMessage="There were no tags added"
                />
              </TagsWrapper>
            </LabelValue>
          </TagsWrapper>
        )}

        <FieldWrapper>
          <LabelValue labelWidth={LABEL_WIDTH} label="Uniform">
            <FormSelect
              isError={errors?.uniform}
              placeholder="Select one"
              value={shift?.uniformTemplateId}
              options={uniformOptions}
              isSmall
              onChange={onUpdate("uniformTemplateId")}
            />
          </LabelValue>
        </FieldWrapper>
        <FieldWrapper isRight>
          <LabelValue labelWidth={LABEL_WIDTH} label="Venue">
            <FormSelect
              isError={errors?.venue}
              placeholder="Select one"
              value={shift?.venueId}
              options={venueOptions}
              isSmall
              onChange={onUpdate("venueId")}
            />
          </LabelValue>
        </FieldWrapper>

        {isProviderScheduler && (
          <FieldWrapper>
            <LabelValue labelWidth={LABEL_WIDTH} label="Sub-venue">
              <FormSelect
                placeholder="Select one"
                value={shift?.subvenueId}
                options={subvenueOptions}
                isSmall
                onChange={onUpdate("subvenueId")}
                isLoading={loadingSubvenue}
              />
            </LabelValue>
          </FieldWrapper>
        )}

        <LabelValue labelWidth={LABEL_WIDTH} label="Briefing">
          <FormSelect
            isError={errors?.briefing}
            shouldShowContentAsSelectedLabel
            shouldFixLabelHeight
            placeholder="Select one"
            value={shift?.privates?.fixedLabel}
            options={briefingOptions}
            isSmall
            onChange={onBriefingUpdate}
          />
        </LabelValue>
        <TextArea
          onChangeValue={onUpdate("briefing")}
          isError={errors?.briefing}
          value={shift?.briefing}
          minRows={4}
          maxRows={4}
          isMultilineAuto
          overrideOrange
          isExtendable
        />
      </FormWrapper>

      <FormWrapper>
        <LabelValue
          labelWidth={LABEL_WIDTH}
          spacingBottom="medium"
          isTopAlignedLabel
          label="Event name"
        >
          <TextInput
            isSmall
            value={shift?.name}
            placeholder="Give your event a name"
            onChangeValue={onUpdate("name")}
          />
        </LabelValue>
        <LabelValue
          labelWidth={LABEL_WIDTH}
          spacingBottom="medium"
          label="My ID"
        >
          <TextInput
            isSmall
            isError={errors?.myId}
            value={shift?.identifier}
            onChangeValue={onUpdate("identifier")}
            placeholder="Add an internal identifier or Purchase Order number"
          />
        </LabelValue>

        {shift && (
          <RotaCheckbox
            label={`Manually manage shift`}
            isChecked={shift?.isPartnerManaged}
            onClick={() => {
              asyncConfirm(
                null,
                {
                  subComponent: () => (
                    <span>
                      <TitleWrapper>
                        <Title>Manage Shifts</Title>
                      </TitleWrapper>
                      <Text>You are currently managing this shift.</Text>
                      <Text>
                        You can let your agency manage the shift for you, but
                        you won't be able to take it back afterwards.
                      </Text>
                      <Text>Who would you like to manage this shift?</Text>
                    </span>
                  ),
                  confirmButtonText: "Agency",
                  falseButtonText: "Still me",
                  onConfirm: () => {
                    onFormUpdate(
                      set(
                        `shifts[${index}].isPartnerManaged`,
                        !shift?.isPartnerManaged,
                        shiftFormState
                      )
                    );
                  }
                },
                MODAL_TYPES.BLACK_AND_ORANGE
              );
            }}
          />
        )}

        {editPolicyMessage || defaultPolicyMessage ? (
          <LabelValue
            labelWidth={LABEL_WIDTH}
            spacingBottom="medium"
            label="Policy"
          >
            <SmallText>
              {editPolicyMessage || defaultPolicyMessage}
              <a href={getPolicyQueryParams(policy)} target="_blank">
                Minimum Shift Length Policy.
              </a>
            </SmallText>
          </LabelValue>
        ) : null}
      </FormWrapper>

      <RequestZonesPanel
        isProvide={isProviderScheduler}
        isShiftCancelled={isShiftCancelled}
        isEditMode
        isVisible
        onUpdate={onFormUpdate}
        initialShift={initialShift}
        selectedAccountId={selectedAccountId}
        formData={shiftFormState}
        hideRestrictCheckbox
      />

      <WarningsWrapper isVisible={warningFields?.length}>
        Attention! This change might cause accepted members to cancel this
        shift.
      </WarningsWrapper>

      {shift.delayHoursAlgoShift > 0 && !shiftOpened && (
        <FormWrapper>
          <OpenShiftButton
            isOpeningShift={isOpeningShift}
            onOpenShift={onOpenShift}
            shift={shift}
          />
          <ManageShiftText>
            This shift is restricted. Open now will open this shift to all
            staff.
          </ManageShiftText>
        </FormWrapper>
      )}
      {initialShift?.isDraft && (
        <FormWrapper>
          <RotaButtonNew
            isLoading={isCancellingShift}
            onClick={onDeleteDraftShift}
            variant="outlined"
          >
            Delete draft
          </RotaButtonNew>
        </FormWrapper>
      )}
      <HasRole role={Role.SHIFTS_CANCEL}>
        {!initialShift?.isDraft && (
          <FormWrapper>
            <RotaButtonNew
              isLoading={isCancellingShift}
              onClick={onCancelShift}
              variant="outlined"
            >
              Cancel shift
            </RotaButtonNew>
            <ManageShiftText>
              Cancelling a shift will notify all accepted members.
            </ManageShiftText>
          </FormWrapper>
        )}
      </HasRole>
      <ButtonFooter>
        <FlexItem>
          <div style={{ width: 10 }} />
        </FlexItem>
        <FlexItem textAlign="center">
          <RotaButtonNew
            isDisabled={flow(
              values,
              some(value => value === true)
            )(errors)}
            isLoading={isSaving}
            onClick={handleOnSave}
          >
            Save
          </RotaButtonNew>
        </FlexItem>

        <HasRole role={Role.SHIFTS_COST}>
          <FlexItem textAlign="right">
            {!isUncalculatedRate && (
              <PricingWrapper>
                {SurgeString({
                  startTime: shift?.startTime,
                  targetAccountId: targetAccountId
                })}
                {shiftTotal}
              </PricingWrapper>
            )}
          </FlexItem>
        </HasRole>
      </ButtonFooter>

      {isAlertOpen && (
        <RotaModal isSmall onClose={handleAlertClose}>
          <StyledModalContainer>
            <StyledIconWrapper>
              <Icon name="BONUS" size="96" />
              <p>Bonus Alert</p>
            </StyledIconWrapper>
            <p>
              The bonus you're about to post
              <strong>
                {" "}
                will apply to accepted members from this point forward.
              </strong>
            </p>
            <p>
              Any members already accepted on the shift
              <strong> will not receive the bonus.</strong>
            </p>
            <p>
              Additionally, the bonus
              <strong>
                {" "}
                cannot be deleted or edited once you click "save"{" "}
              </strong>
              in the edit shift modal.
            </p>
            <StyledModalButtons>
              <RotaButtonNew onClick={handleAlertClose} variant="outlined">
                Cancel
              </RotaButtonNew>
              <RotaButtonNew onClick={handleAlertConfirm}>
                Confirm
              </RotaButtonNew>
            </StyledModalButtons>
          </StyledModalContainer>
        </RotaModal>
      )}
      <ShiftUpdateMemberConflict
        warning={warning}
        onClose={() => {
          setWarning(null);
        }}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  bonuses: state.calendar.bonuses,
  isBonusPolicyConfirmed: state.calendar.isBonusPolicyConfirmed
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addBonus: reducerActions.addBonus,
      addBonusWithData: reducerActions.addBonusWithData,
      removeBonus: reducerActions.removeBonus,
      updateBonusType: reducerActions.updateBonusType,
      updateBonusAmount: reducerActions.updateBonusAmount,
      updateTotalBonus: reducerActions.updateTotalBonus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getProfile(EditShift));
