import pick from "lodash/fp/pick";
import { POOL_TYPES } from "@teamrota/rota-common";

// the default timezone
// this could also be drawn from profile in future
export const ACCOUNT_TIMEZONE = "Europe/Dublin";

export const PARTNER_MANAGED_THRESHOLD = 2;

export const ALLOW_TAGS = {
  ON_SHIFTS: true
};

export const BONUS_TYPES = {
  HOURLY: "hourly",
  FIXED: "fixed"
};

// *** WARNING ***
// Altering the order or details of these constants can cause members in the mobile-app
// to see incorrect uniforms, since we can't guarantee when mobile-apps get updated.
// Do not alter, only append.
// See: https://rota.atlassian.net/browse/RD-2794
// https://rota.atlassian.net/browse/RD-2823
// Any modifications here must be replicated in all three of mobile-app, platform and automation-service
// ***
export const DRESS_CODE_IMAGES = [
  {
    uri: "https://doc.rota.com/dress-code-1.png",
    label: "Black shirt, black bow tie, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-2.png",
    label: "Black shirt, black thin tie, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-3.png",
    label:
      "Black shirt, black thin tie, black waistcoat, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-4.png",
    label: "Black shirt, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-5.png",
    label: "Black t-shirt, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-6.png",
    label: "White shirt, black bow tie, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-7.png",
    label:
      "White shirt, black bow tie, black waistcoat, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-8.png",
    label: "White shirt, black thin tie, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-9.png",
    label:
      "White shirt, black thin tie, black waistcoat, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-10.png",
    label: "White shirt, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-11.png",
    label: "White t-shirt, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-12.png",
    label:
      "Black shirt, black bow tie, black waistcoat, black trousers, black shoes"
  },
  {
    uri: "https://doc.rota.com/dress-code-13.png",
    label: "High visibility vest, safety shoes"
  }
];

export const PAGESCROLL_ELEMENT_ID = "pagescroll-element-id";

export const REPORT_REQUEST_TYPES = {
  MY_STAFF: "MY_STAFF",
  PAYROLL: "PAYROLL"
};

export const REPORT_STATUS = {
  CREATED: "CREATED",
  GENERATING: "GENERATING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
  NO_DATA: "NO_DATA"
};

// GLOBAL BOOKING STATE LABELS
export const BOOKING_STATES_LABELS = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  CONFLICTED: "Conflicted",
  DECLINED: "Declined", // When the worker turns down a shift offer
  REJECTED: "Rejected", // When the worker is not good enough for the shift
  NO_SHOW: "No show",
  WAITING_LIST: "Waiting list",
  SHIFT_CANCELLED: "Shift cancelled",
  APP_CANCELLED: "App cancelled", // When the worker cancels themself in app
  CANCELLED: "Agent cancellation",
  TURNED_AWAY: "Turned away"
};

export const BOOKING_STATES = {
  ACCEPTED: "ACCEPTED",
  APPLIED: "APPLIED",
  APP_CANCELLED: "APP_CANCELLED", // When the member cancels off of the shift himself
  CANCELLED: "CANCELLED", // When the ops cancel a member off of the shift
  CONFLICTED: "CONFLICTED",
  DECLINED: "DECLINED", // When the member turns down a shift offer
  MATCHED: "MATCHED",
  NO_SHOW: "NO_SHOW",
  PARTNER_DECLINED: "PARTNER_DECLINED", // When the partner declines a member in a managed shift
  REJECTED: "REJECTED", // When the member is not a good fit for the shift
  SHIFT_CANCELLED: "SHIFT_CANCELLED",
  TURNED_AWAY: "TURNED_AWAY",
  WAITING_LIST: "WAITING_LIST"
};

export const MEMBER_GRID_LABELS = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  PARTNER_DECLINED: "Declined",
  WAITING_LIST: "Waiting list",
  REQUESTED: "Requested",
  CANCELLED: "Cancelled",
  APP_CANCELLED: "App cancelled"
};

export const PENCE_POUNDS_WITH_TAX = 0.012;
export const PENCE_POUNDS_TAX = 0.002;
export const PENCE_POUNDS = 0.01;

export const UPDATABLE_BOOKING_STATES = pick(
  [
    BOOKING_STATES.APPLIED,
    BOOKING_STATES.ACCEPTED,
    BOOKING_STATES.WAITING_LIST,
    BOOKING_STATES.CANCELLED,
    BOOKING_STATES.NO_SHOW,
    BOOKING_STATES.TURNED_AWAY
  ],
  BOOKING_STATES_LABELS
);

export const BUCKET_REASON = {
  ASSIGNED: "ASSIGNED",
  REQUESTED: "REQUESTED",
  OPS_CREATED: "OPS_CREATED"
};

export const SHIFT_STATES = {
  POSTED: "Posted", // created
  FINALISED: "Finalised", // when requested staff filled OR, generally ready to go.
  UNFINALISED: "Unfinalised",
  NOT_FINALISED: "Not finalised", // when the shift is not finalised
  REVIEWED: "Reviewed", // requester has approved/reviewed checkin/checkout times
  CANCELLED: "Cancelled", // shift was cancelled
  VISIBLE: "Visible",
  UNLINKED: "Unlinked"
};

export const APPROVAL_STATES = {
  UPCOMING: "UPCOMING",
  PENDING_MEMBER: "PENDING_MEMBER",
  PENDING_ACCOUNT: "PENDING_ACCOUNT",
  DISPUTE_WINDOW: "DISPUTE_WINDOW",
  UNDER_REVIEW: "UNDER_REVIEW",
  APPROVED: "APPROVED",
  NO_BOOKINGS: "NO_BOOKINGS"
};

export const APPROVAL_STATES_LABELS = {
  UPCOMING: "Upcoming",
  PENDING_MEMBER: "Pending member",
  PENDING_ACCOUNT: "Pending account",
  DISPUTE_WINDOW: "Dispute window",
  UNDER_REVIEW: "Under review",
  APPROVED: "Approved",
  NO_BOOKINGS: "No bookings"
};

export const BOOKING_APPROVAL_METHODS = {
  MEMBER_APP: "MEMBER_APP",
  MANAGER_APP: "MANAGER_APP",
  PROVIDER: "PROVIDER"
};

// -> rota-docs docs/product/members/member-status.md
export const MEMBER_STATES = {
  ACTIVE: "ACTIVE",
  ASLEEP: "ASLEEP",
  GOOD_LEAVER: "GOOD_LEAVER",
  LIVE: "LIVE",
  LOCKED: "LOCKED",
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  REMOVED: "REMOVED"
};

export const LOADING_SPINNER_GREY = "#eee";

export const GOOGLE_MAP_URL =
  "https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=AIzaSyD5YAoSiVOJnfRZOZd4SQ8VyjooITdp2m4";

export const ZENDESK_SUPPORT_LINK =
  "https://rota.zendesk.com/hc/en-gb/requests/new?ticket_form_id=360000260397";

export const POOL_LIMITS = {
  SMALL: 20,
  LARGE: 50
};

export const SHIFT_TYPE = {
  ONBOARDING: "Rota registration"
};

export const POOL_TYPE_COLOR = {
  [POOL_TYPES.RECOMMENDED]: "MAIN_ORANGE",
  [POOL_TYPES.FAVOURITED]: "PINK",
  [POOL_TYPES.BLACKLISTED]: "DARK_GREY"
};

export const STAFF_POOLS_SIZE = {
  large: "590px",
  medium: "230px",
  small: "130px"
};

export const DOCUMENT_TYPES = {
  MEMBER_PHOTO: "MEMBER_PHOTO",
  USER_PHOTO: "USER_PHOTO",
  ADDITIONAL_MEMBER_DOCUMENT: "ADDITIONAL_MEMBER_DOCUMENT",
  ADDITIONAL_USER_DOCUMENT: "ADDITIONAL_USER_DOCUMENT",
  RIGHT_TO_WORK: "RIGHT_TO_WORK",
  ACCOUNT_LOGO: "ACCOUNT_LOGO",
  ACCOUNT_BANNER: "ACCOUNT_BANNER",
  DBS: "DBS",
  DBS_BASIC: "DBS_BASIC",
  DBS_ENHANCED: "DBS_ENHANCED",
  ENHANCED_CHILD_DBS: "ENHANCED_CHILD_DBS",
  REFERENCE: "REFERENCE",
  REFERENCE_2: "REFERENCE_2",
  HEALTH_SAFETY_LEVEL_1: "HEALTH_SAFETY_LEVEL_1",
  HEALTH_SAFETY_LEVEL_2: "HEALTH_SAFETY_LEVEL_2",
  HEALTH_SAFETY_LEVEL_3: "HEALTH_SAFETY_LEVEL_3",
  ALLERGEN_AWARENESS: "ALLERGEN_AWARENESS",
  FLOW_TRAINING: "FLOW_TRAINING",
  PROOF_OF_ADDRESS: "PROOF_OF_ADDRESS",
  NATIONAL_INSURANCE: "NATIONAL_INSURANCE",
  COVID_VACCINE_PASSPORT: "COVID_VACCINE_PASSPORT",
  CV: "CV",
  TRADE_TEST: "TRADE_TEST",
  UNAVAILABILITY_DOCUMENT: "UNAVAILABILITY_DOCUMENT",
  OTHER: "OTHER",
  EVOLVE_SIGNUP_FORM: "EVOLVE_SIGNUP_FORM",
  EVOLVE_KEY_INFORMATION_DOCUMENT: "EVOLVE_KEY_INFORMATION_DOCUMENT",
  EVOLVE_TERMS_OF_ENGAGEMENT: "EVOLVE_TERMS_OF_ENGAGEMENT",
  EVOLVE_MEDICAL_STATEMENT: "EVOLVE_MEDICAL_STATEMENT",
  EVOLVE_UNIFORM_STATEMENT: "EVOLVE_UNIFORM_STATEMENT",
  EVOLVE_GDPR_CONSENT: "EVOLVE_GDPR_CONSENT",
  DEED_OF_NAME_CHANGE: "DEED_OF_NAME_CHANGE",
  MARRIAGE_CERTIFICATE: "MARRIAGE_CERTIFICATE",
  UNIVERSITY_ENROLMENT_LETTER: "UNIVERSITY_ENROLMENT_LETTER",
  UNIVERSITY_TERM_DATES: "UNIVERSITY_TERM_DATES",
  HOME_OFFICE_LETTER: "HOME_OFFICE_LETTER",
  SPOUSAL_DOCUMENT: "SPOUSAL_DOCUMENT",
  BIRTH_CERTIFICATE: "BIRTH_CERTIFICATE",
  FOOD_SAFETY_LEVEL_1: "FOOD_SAFETY_LEVEL_1",
  FOOD_SAFETY_LEVEL_2: "FOOD_SAFETY_LEVEL_2",
  FOOD_SAFETY_LEVEL_3: "FOOD_SAFETY_LEVEL_3",
  BOOKING_CONTRACT: "BOOKING_CONTRACT",
  MANUAL_HANDLING: "MANUAL_HANDLING",
  COSHH: "COSHH",
  HACCP: "HACCP",
  FIRE_SAFETY: "FIRE_SAFETY",
  SLIPS_TRIPS_FALLS_AWARENESS: "SLIPS_TRIPS_FALLS_AWARENESS",
  APPLICATION_FORM: "APPLICATION_FORM",
  BASIC_FIRST_AID: "BASIC_FIRST_AID",
  BUCCAL: "BUCCAL",
  CHILD_PROTECTION: "CHILD_PROTECTION",
  CLEARANCE: "CLEARANCE",
  CORU: "CORU",
  CORU_PIN_CHECK: "CORU_PIN_CHECK",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  EVIDENCE_OF_PPS: "EVIDENCE_OF_PPS",
  FIRE_SAFETY_AWARENESS_ONLINE: "FIRE_SAFETY_AWARENESS_ONLINE",
  GARDA_VETTING_FW: "GARDA_VETTING_FW",
  GARDA_VETTING_NFW: "GARDA_VETTING_NFW",
  GARDA_VETTING_APPLICATION_FORM: "GARDA_VETTING_APPLICATION_FORM",
  GDPR: "GDPR",
  HAND_HYGIENE: "HAND_HYGIENE",
  HEALTHIER_BUSINESS_FORM: "HEALTHIER_BUSINESS_FORM",
  HEARTSAVER_AED_CPR: "HEARTSAVER_AED_CPR",
  INFECTION_PREVENTION_CONTROL: "INFECTION_PREVENTION_CONTROL",
  INTERVIEW_NOTES: "INTERVIEW_NOTES",
  IRISH_MEDICAL_COUNCIL: "IRISH_MEDICAL_COUNCIL",
  IRISH_MEDICAL_COUNCIL_PIN_CHECK: "IRISH_MEDICAL_COUNCIL_PIN_CHECK",
  MAPA: "MAPA",
  MEDICATION_MANAGEMENT: "MEDICATION_MANAGEMENT",
  NMBI_PIN_CHECK: "NMBI_PIN_CHECK",
  NMBI_REGISTRATION: "NMBI_REGISTRATION",
  OCCUPATIONAL_FIRST_AID: "OCCUPATIONAL_FIRST_AID",
  OCCUPATIONAL_HEALTH: "OCCUPATIONAL_HEALTH",
  PASSPORT_PHOTO: "PASSPORT_PHOTO",
  PATIENT_MOVING_HANDLING: "PATIENT_MOVING_HANDLING",
  QC_NOTE: "QC_NOTE",
  QUALIFICATIONS: "QUALIFICATIONS",
  REFERENCE_3: "REFERENCE_3",
  RISK_ASSESSMENT: "RISK_ASSESSMENT",
  SAFE_ADMINISTRATION_OF_MEDICATION: "SAFE_ADMINISTRATION_OF_MEDICATION",
  SAFEGUARDING_OF_VULNERABLE_ADULTS: "SAFEGUARDING_OF_VULNERABLE_ADULTS",
  SIGN_OFF: "SIGN_OFF",
  SKYPE_SCREENING_CALL: "SKYPE_SCREENING_CALL",
  TCI: "TCI",
  VACCINATIONS: "VACCINATIONS",
  VERIFICATION_OF_SERVICE: "VERIFICATION_OF_SERVICE"
};

export const RIGHT_TO_WORK_SUBTYPES = {
  UK_PASSPORT: "UK_PASSPORT",
  FULL_BIRTH_CERTIFICATE: "FULL_BIRTH_CERTIFICATE",
  CERTIFICATE_OF_NATURALISATION: "CERTIFICATE_OF_NATURALISATION",
  EU_NATIONAL_ID_CARD: "EU_NATIONAL_ID_CARD",
  EU_PASSPORT: "EU_PASSPORT",
  EU_PERMANENT_RESIDENCE_CERTIFICATE: "EU_PERMANENT_RESIDENCE_CERTIFICATE",
  BIOMETRIC_RESIDENT_PERMIT: "BIOMETRIC_RESIDENT_PERMIT",
  NON_EU_PASSPORT: "NON_EU_PASSPORT",
  IMMIGRATION_STATUS_DOCUMENT: "IMMIGRATION_STATUS_DOCUMENT",
  POSITIVE_VERIFICATION_NOTICE: "POSITIVE_VERIFICATION_NOTICE",
  STUDENT_VISA_10_HOURS: "STUDENT_VISA_10_HOURS",
  STUDENT_VISA_20_HOURS: "STUDENT_VISA_20_HOURS",
  BIOMETRIC_STUDENT_VISA: "BIOMETRIC_STUDENT_VISA",
  EEA_SETTLEMENT_STATUS_REPORT: "EEA_SETTLEMENT_STATUS_REPORT",
  EEA_SETTLEMENT_STATUS_REPORT_SETTLED: "EEA_SETTLEMENT_STATUS_REPORT_SETTLED",
  PASSPORT_WITH_RIGHT_OF_RESIDENCE_EEA_NATIONAL_FAMILY_MEMBER:
    "PASSPORT_WITH_RIGHT_OF_RESIDENCE_EEA_NATIONAL_FAMILY_MEMBER",
  PASSPORT_WITH_VISA_GIVING_INDEFINITE_LEAVE_TO_REMAIN:
    "PASSPORT_WITH_VISA_GIVING_INDEFINITE_LEAVE_TO_REMAIN",
  PASSPORT_WITH_VISA_GIVING_LIMITED_LEAVE_TO_REMAIN:
    "PASSPORT_WITH_VISA_GIVING_LIMITED_LEAVE_TO_REMAIN"
};

export const DOCUMENT_TYPES_LABELS = {
  MEMBER_PHOTO: "Photo",
  ADDITIONAL_MEMBER_DOCUMENT: "Other",
  ADDITIONAL_USER_DOCUMENT: "Other",
  RIGHT_TO_WORK: "Right to work document",
  DBS: "DBS (enhanced/basic)",
  DBS_BASIC: "DBS Basic",
  DBS_ENHANCED: "DBS Enhanced",
  ENHANCED_CHILD_DBS: "Enhanced Child DBS",
  REFERENCE: "Reference",
  REFERENCE_2: "Reference 2",
  HEALTH_SAFETY_LEVEL_1: "Health & Safety Level 1",
  HEALTH_SAFETY_LEVEL_2: "Health & Safety Level 2",
  HEALTH_SAFETY_LEVEL_3: "Health & Safety Level 3",
  ALLERGEN_AWARENESS: "Allergen Awareness",
  FLOW_TRAINING: "FLOW Assessment",
  PROOF_OF_ADDRESS: "Proof Of Address",
  NATIONAL_INSURANCE: "National Insurance",
  COVID_VACCINE_PASSPORT: "COVID vaccine Passport",
  CV: "CV",
  TRADE_TEST: "Trade Test",
  OTHER: "Other",
  EVOLVE_SIGNUP_FORM: "Registration Form",
  EVOLVE_MEDICAL_STATEMENT: "Medical Statement",
  EVOLVE_KEY_INFORMATION_DOCUMENT: "Key Information document",
  EVOLVE_TERMS_OF_ENGAGEMENT: "Terms of Engagement",
  EVOLVE_UNIFORM_STATEMENT: "Uniform statement",
  EVOLVE_GDPR_CONSENT: "GDPR Consent Form",
  DEED_OF_NAME_CHANGE: "Deed of Name Change",
  MARRIAGE_CERTIFICATE: "Marriage Certificate",
  UNIVERSITY_ENROLMENT_LETTER: "University Enrolment Letter",
  UNIVERSITY_TERM_DATES: "University Term Dates",
  HOME_OFFICE_LETTER: "Home Office Letter",
  SPOUSAL_DOCUMENT: "Spousal Document",
  BIRTH_CERTIFICATE: "Birth Certificate",
  FOOD_SAFETY_LEVEL_1: "Food Safety Level 1",
  FOOD_SAFETY_LEVEL_2: "Food Safety Level 2",
  FOOD_SAFETY_LEVEL_3: "Food Safety Level 3",
  BOOKING_CONTRACT: "Booking Contract",
  MANUAL_HANDLING: "Manual Handling",
  COSHH: "COSHH",
  HACCP: "HACCP",
  FIRE_SAFETY: "Fire Safety",
  SLIPS_TRIPS_FALLS_AWARENESS: "Slips, Trips & Falls Awareness",
  APPLICATION_FORM: "Application Form",
  BASIC_FIRST_AID: "Basic First Aid",
  BUCCAL: "BUCCAL",
  CHILD_PROTECTION: "Child Protection",
  CLEARANCE: "Clearance",
  CORU: "CORU",
  CORU_PIN_CHECK: "CORU Pin check",
  DRIVERS_LICENSE: "Drivers License",
  EVIDENCE_OF_PPS: "Evidence of PPS",
  FIRE_SAFETY_AWARENESS_ONLINE: "Fire Safety Awareness Online",
  GARDA_VETTING_FW: "Garda Vetting FW",
  GARDA_VETTING_NFW: "Garda Vetting NFW",
  GARDA_VETTING_APPLICATION_FORM: "Garda Vetting application form",
  GDPR: "GDPR",
  HAND_HYGIENE: "Hand Hygiene",
  HEALTHIER_BUSINESS_FORM: "Healthier Business Form",
  HEARTSAVER_AED_CPR: "Heartsaver AED & CPR",
  INFECTION_PREVENTION_CONTROL: "Infection Prevention & Control",
  INTERVIEW_NOTES: "Interview Notes",
  IRISH_MEDICAL_COUNCIL: "Irish Medical Council",
  IRISH_MEDICAL_COUNCIL_PIN_CHECK: "Irish Medical Council Pin Check",
  MAPA: "MAPA",
  MEDICATION_MANAGEMENT: "Medication Management",
  NMBI_PIN_CHECK: "NMBI Pin check",
  NMBI_REGISTRATION: "NMBI Registration",
  OCCUPATIONAL_FIRST_AID: "Occupational First Aid",
  OCCUPATIONAL_HEALTH: "Occupational Health / Fit to work",
  PASSPORT_PHOTO: "Passport Photo",
  PATIENT_MOVING_HANDLING: "Patient Moving & Handling",
  QC_NOTE: "QC Note",
  QUALIFICATIONS: "Qualifications",
  REFERENCE_3: "Reference 3",
  RISK_ASSESSMENT: "Risk Assessment",
  SAFE_ADMINISTRATION_OF_MEDICATION: "Safe Administration of Medication",
  SAFEGUARDING_OF_VULNERABLE_ADULTS: "Safeguarding of Vulnerable Adults",
  SIGN_OFF: "Sign Off",
  SKYPE_SCREENING_CALL: "Skype / Screening call",
  TCI: "TCI",
  VACCINATIONS: "Vaccinations",
  VERIFICATION_OF_SERVICE: "Verification of Service"
};

export const RIGHT_TO_WORK_SUBTYPES_LABELS = {
  UK_PASSPORT: "UK Passport",
  FULL_BIRTH_CERTIFICATE: "Birth Certificate + proof of NI",
  CERTIFICATE_OF_NATURALISATION: "Certificate of Naturalisation",
  EU_NATIONAL_ID_CARD: "EEA ID Card",
  EU_PASSPORT: "EEA Passport",
  EU_PERMANENT_RESIDENCE_CERTIFICATE: "EU Permanent Residence Certificate",
  BIOMETRIC_RESIDENT_PERMIT:
    "Biometric Residence Permit Giving Indefinite Leave to Remain",
  NON_EU_PASSPORT: "Non EU passport",
  IMMIGRATION_STATUS_DOCUMENT: "Immigration Status Document",
  POSITIVE_VERIFICATION_NOTICE: "ECS Check Positive Verification",
  STUDENT_VISA_10_HOURS: "Student VISA 10 hours",
  STUDENT_VISA_20_HOURS: "Student VISA 20 hours",
  BIOMETRIC_STUDENT_VISA: "Biometric Student Visa",
  EEA_SETTLEMENT_STATUS_REPORT: "EEA Settlement Status Report",
  EEA_SETTLEMENT_STATUS_REPORT_SETTLED:
    "EEA Settlement Status Report (Settled)",
  PASSPORT_WITH_RIGHT_OF_RESIDENCE_EEA_NATIONAL_FAMILY_MEMBER:
    "Passport with Right of Residence as a Family Member of an EEA National",
  PASSPORT_WITH_VISA_GIVING_INDEFINITE_LEAVE_TO_REMAIN:
    "Passport with Visa giving Indefinite Leave to Remain",
  PASSPORT_WITH_VISA_GIVING_LIMITED_LEAVE_TO_REMAIN:
    "Passport with Visa giving Limited Leave to Remain"
};

export const ROTA_ACCOUNT = "Rota - Staff on Demand";
export const ROTA_ACCOUNT_ID = 1;

export const GENDER_OPTIONS = ["Male", "Female", "Other"];

export const BOOLEAN_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

export const MEMBER_TYPES = [
  { value: "CASUAL", label: "CASUAL" },
  { value: "PERMANENT", label: "PERMANENT" },
  { value: "AGENCY", label: "AGENCY" },
  { value: "CONTRACTOR_LIMITED_COMPANY", label: "CONTRACTOR LIMITED COMPANY" },
  {
    value: "CONTRACTOR_UMBRELLA_COMPANY",
    label: "CONTRACTOR UMBRELLA COMPANY"
  },
  { value: "CONTRACTOR_SELF_EMPLOYED", label: "CONTRACTOR SELF EMPLOYED" }
];

export const MEMBER_INFO = [
  { value: "REQUIRES_TRAVEL", label: "REQUIRES TRAVEL" }
];

export const SALARIED_MEMBER_TYPES = [
  "PERMANENT",
  "CONTRACTOR_LIMITED_COMPANY",
  "CONTRACTOR_UMBRELLA_COMPANY",
  "CONTRACTOR_SELF_EMPLOYED"
];

export const MENU_OPTIONS = {
  ONBOARDING: [
    { label: "Accept", value: "ACTIVE" },
    { label: "Reject", value: "REJECTED" }
  ]
};

export const SHIFT_TIME_PRIORITY_START = [
  "startOnPoolManagerOverride",
  "startOnApply",
  "startOnApproval",
  "shiftStartTime"
];

export const SHIFT_TIME_PRIORITY_END = [
  "endOnPoolManagerOverride",
  "endOnApply",
  "endOnApproval",
  "shiftEndTime"
];

export const skewOptions = [
  { value: 100, label: "Standard Quality, Standard fulfillabilty" },
  { value: 150, label: "High quality, Reduced fulfillabilty" },
  { value: 50, label: "High fulfillabilty, Reduced quality" }
];

export const NOTIFICATION_TYPES = {
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  TEXT: "TEXT",
  EMAIL: "EMAIL"
};

export const CANCELLATION_REASONS = {
  CONFIDENCE: "Service Issue - Fulfilment Confidence",
  ACCOUNTMANAGEMENT: "Service Issue - Account Management",
  MISTAKE: "Partner Issue - User Error",
  REDUCED_DEMAND: "Partner Issue - Reduced Demand",
  OWNSTAFF: "Partner Issue - Already Filled In-house",
  OTHERSUPPLIER: "Partner Issue - Already Filled Elsewhere"
};

export const REFERRAL_METHODS = [
  { name: "Rota", id: "Rota" },
  { name: "Client Recommended", id: "Client Recommended" },
  { name: "LinkedIn", id: "LinkedIn" },
  { name: "Indeed", id: "Indeed" },
  { name: "Job Fairs", id: "Job Fairs" },
  { name: "Job Today", id: "Job Today" },
  { name: "Other", id: "Other" },
  { name: "Re-Registered", id: "Re-Registered" },
  { name: "Referred Friend", id: "Referred Friend" }
];

export const ROLE_TABS = ["Calculated", "Uncalculated"];

export const MAX_UPLOAD_SIZE_MB = 50;

export const PHONE_NUMBER_REGEX = /^\+?[0-9]{8,12}$/;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const SHIFT_TYPES = {
  DAY: "DAY",
  NIGHT: "NIGHT"
};

export const DND_ITEM_TYPES = {
  MEMBER: "MEMBER",
  POOL_MEMBER: "POOL_MEMBER",
  OPEN_SHIFT: "OPEN_SHIFT"
};

export const AVAILABILITY_TYPES = {
  DAY: "DAY",
  NIGHT: "NIGHT",
  ALL: "ALL"
};

export const SORT_BY = {
  FIRSTNAME: "FIRSTNAME",
  LASTNAME: "LASTNAME",
  RATING: "RATING",
  MOST_SHIFTS_WORKED: "MOST_SHIFTS_WORKED",
  LEAST_SHIFTS_WORKED: "LEAST_SHIFTS_WORKED"
};

export const MAX_WEEKLY_HOURS = 60;

export const BUILTIN_USER_TYPES = {
  SUPER_ADMIN: 1,
  GENERAL_ADMIN: 2,
  MANAGER: 3,
  ACCOUNTANT: 4,
  CONSULTANT: 5
};
