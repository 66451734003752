import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { addAuthVars } from "@teamrota/authlib";

const GET_SHIFT_SUBVENUE = addAuthVars(gql`
  query getShiftSubvenueDetails($id: ID!) {
    account {
      id
      shift(id: $id) {
        id
        subvenue {
          id
          name
        }
      }
    }
  }
`);

export const useShiftSubvenue = ({ shiftId }) => {
  const { data, loading, error } = useQuery(GET_SHIFT_SUBVENUE, {
    skip: !shiftId,
    variables: {
      id: shiftId
    },
    networkPolicy: "network-only",
    onError: error => {
      throw error;
    }
  });

  return {
    subvenue: data?.account?.shift.subvenue,
    loadingSubvenue: loading,
    error
  };
};
