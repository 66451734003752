import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Role } from "@teamrota/authlib";

import {
  RotaButtonNew,
  RotaSearchInput,
  RotaButtonIcon,
  iconNew
} from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import HasRole from "~/src/containers/has-role";

import {
  Wrapper,
  HeaderWrapper,
  Box,
  Heading,
  Title,
  Actions,
  Subheader,
  SubheaderWrapper
} from "~/src/components/payroll/style";

import { useCreatePayrollRule } from "./graphql/use-create-payroll-rule";
import { useValidatePayrollRule } from "./graphql/use-validate-payroll-rule";
import { useUpdatePayrollRule } from "./graphql/use-update-payroll-rule";
import { usePayrollRules } from "./graphql/use-payroll-rules";

import JsonEditModal from "../payroll/components/JsonEditModal";

import Table from "./Table";
import Settings from "./settings";

const { Filter, ChevronLeft } = iconNew;

const ExampleJsonInput = {
  name: "Name",

  config: [
    {
      name: "name",
      type: "block",
      conditions: {
        "block.begins >=": "00:00",
        "block.begins <": "22:00"
      },
      event: {
        type: "rate",
        params: {
          PAY: ["BASE", "SUND5DAY"],
          CHARGE: ["PAY", "HOLIDAY", "PRSI", "MARGIN", "ADMIN"]
        }
      }
    }
  ]
};

const Rules = () => {
  const history = useHistory();
  const params = useParams() as any;
  const auth = useAuth();

  const canEdit = auth.hasRole(Role.PAYROLL_EDIT);

  const { createPayrollRule } = useCreatePayrollRule();
  const { validatePayrollRule } = useValidatePayrollRule();
  const { updatePayrollRule } = useUpdatePayrollRule();

  const { payrollRules } = usePayrollRules("");

  const [isShowCreateModal, setIsShowCreateModal] = useState(false);

  const [selected, setSelected] = useState<any>(null);

  const handleOpenRule = (id: string) =>
    setSelected(payrollRules.find((p: any) => p.id === id) ?? null);

  useEffect(() => {
    if (params.id !== undefined) handleOpenRule(params.id);
  }, [params, payrollRules]);

  const handleClose = () => {
    setSelected(null);
    history.push("/payroll/rules");
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaSearchInput onChange={() => {}} />
        <HasRole role={Role.PAYROLL_EDIT}>
          <RotaButtonNew onClick={() => setIsShowCreateModal(true)}>
            Create
          </RotaButtonNew>
        </HasRole>
      </HeaderWrapper>
      <SubheaderWrapper>
        <RotaButtonIcon onClick={() => history.push("/payroll")}>
          <ChevronLeft />
        </RotaButtonIcon>
        <Subheader>Rules</Subheader>
      </SubheaderWrapper>
      <Box>
        <Heading>
          <Title>{`All ${payrollRules?.length} records`}</Title>
          <Actions>
            <RotaButtonIcon onClick={() => {}}>
              <Filter />
            </RotaButtonIcon>
          </Actions>
        </Heading>

        <Table
          payrollRules={payrollRules}
          handleOpenRule={handleOpenRule}
          canEdit={canEdit}
        />
      </Box>

      {isShowCreateModal && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await createPayrollRule({ name, config });
            setIsShowCreateModal(false);
          }}
          onValidate={({ name, config }) =>
            validatePayrollRule({ name, config })
          }
          onClose={() => setIsShowCreateModal(false)}
          value={ExampleJsonInput}
          title="Create rules"
        />
      )}

      {selected && (
        <JsonEditModal
          canEdit={canEdit}
          onSave={async ({ name, config }) => {
            await updatePayrollRule({
              id: selected.id,
              name,
              config,
              twilightStart: selected?.twilightEnd && selected?.twilightStart,
              twilightEnd: selected?.twilightStart && selected?.twilightEnd
            });

            handleClose();
          }}
          onValidate={({ name, config }) =>
            validatePayrollRule({ name, config })
          }
          onClose={handleClose}
          value={selected}
          title="Edit rules"
        >
          <Settings value={selected} onChange={setSelected} />
        </JsonEditModal>
      )}
    </Wrapper>
  );
};

export default Rules;
