import React, { useContext, useEffect } from "react";
import { useDrag } from "react-dnd";
import { useTheme } from "styled-components";
import { iconNew } from "@teamrota/rota-design";

import { SHIFT_TYPES, DND_ITEM_TYPES } from "~/src/consts";
import { formatHours } from "~/src/containers/scheduler/utils";
import { FocusShiftContext } from "~/src/containers/provide-schedule/focus-shift-context";
import { StyledOpenShift, Div } from "./open-shift-weekly-card.styles";

const { Moon, Sun } = iconNew;

const OpenShiftWeeklyCard = ({
  shifts,
  setSelectedDayIndex,
  dayIndex,
  lastDraggedShiftId,
  setLastDraggedShiftId
}) => {
  const theme = useTheme();

  const { setFocusShift } = useContext(FocusShiftContext);

  const isMultipleShifts = shifts?.length > 1;
  let dragRef = null;

  if (!isMultipleShifts) {
    const shift = shifts?.[0];

    const [{ draggedShift, isDragging }, drag] = useDrag(
      () => ({
        type: DND_ITEM_TYPES.OPEN_SHIFT,
        item: () => shift,
        canDrag: () => true,
        collect: monitor => ({
          isDragging: monitor.isDragging(),
          draggedShift: monitor.getItem()
        })
      }),
      [shift]
    );

    useEffect(() => {
      if (isDragging && draggedShift.id !== lastDraggedShiftId) {
        setLastDraggedShiftId(draggedShift?.id);
      }
    }, [isDragging]);

    dragRef = drag;
  }

  const dayShiftsLength = shifts?.filter(
    shift => shift.type !== SHIFT_TYPES.NIGHT
  ).length;
  const nightShiftsLength = shifts?.filter(
    shift => shift.type === SHIFT_TYPES.NIGHT
  ).length;

  const isCancelled = isMultipleShifts ? false : !!shifts?.[0]?.cancelledAt;
  const isDraft = isMultipleShifts ? false : !!shifts?.[0]?.isDraft;
  const hasCancelledBookings = isMultipleShifts
    ? false
    : !!shifts?.[0]?.hasCancelledBookings;

  const isNightShift =
    !isMultipleShifts && shifts?.[0]?.type === SHIFT_TYPES.NIGHT;

  const isStream = !isMultipleShifts && shifts?.[0]?.stream?.id;

  const textColor =
    isNightShift && !(isDraft || isCancelled || hasCancelledBookings)
      ? theme.white
      : theme.text.primary;

  return (
    <StyledOpenShift
      ref={dragRef}
      backgroundColor={
        isCancelled
          ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#ffeaea 10px 20px)"
          : isDraft
          ? "white"
          : isStream && isNightShift
          ? "#052d63"
          : isStream
          ? "#A3CAFF"
          : hasCancelledBookings
          ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#eebb0055 10px 20px)"
          : isNightShift
          ? theme.background.secondary
          : theme.primary.lighter
      }
      isNightShift={isNightShift}
      borderColor={
        isCancelled
          ? theme.error.main
          : hasCancelledBookings
          ? theme.warning.main
          : isStream
          ? "#2f84f7"
          : theme.primary.main
      }
      isDraft={isDraft}
      isMultipleShifts={isMultipleShifts}
      onClick={() => {
        setSelectedDayIndex(dayIndex);
        if (!isMultipleShifts) {
          setFocusShift(shifts?.[0]);
        }
      }}
    >
      {isMultipleShifts && (
        <>
          <Div fontSize="14px" fontWeight="500" margin="5px 0">
            <Sun />
            <Div fontSize="14px" fontWeight="500" margin="0 0 0 5px">
              {`${dayShiftsLength} ${
                dayShiftsLength === 1 ? "shift" : "shifts"
              }`}
            </Div>
          </Div>
          <Div fontSize="14px" fontWeight="500" margin="5px 0">
            <Moon />
            <Div fontSize="14px" fontWeight="500" margin="0 0 0 5px">
              {" "}
              {`${nightShiftsLength} ${
                nightShiftsLength === 1 ? "shift" : "shifts"
              }`}
            </Div>
          </Div>
        </>
      )}
      {!isMultipleShifts && (
        <>
          <Div fontSize="14px" margin="5px 0" color={textColor}>
            {shifts?.[0]?.roleRate.roleName}
          </Div>
          <Div
            fontSize="14px"
            fontWeight="500"
            margin="5px 0"
            color={textColor}
          >
            {formatHours(shifts?.[0])}
          </Div>
          <Div fontSize="14px" margin="5px 0" color={textColor}>
            {`${shifts?.[0]?.bookings.totalResults}/${shifts?.[0]?.numberRequested}`}
          </Div>
        </>
      )}
    </StyledOpenShift>
  );
};

export default OpenShiftWeeklyCard;
