import React from "react";
import { connect } from "react-redux";
import { SHIFT_TYPES } from "~/src/consts";

import {
  GridCardWrapper,
  Div,
  StyledMaskContainer
} from "~/src/containers/scheduler/GridCard/grid-card.styles";

import { formatHours } from "../utils";

const GridCardContent = ({ shiftData, booking, onClick = { onClick } }) => {
  const cardData = shiftData || booking?.[0];
  const roleName = cardData?.shift?.roleRate?.roleName;
  const isShiftCancelled = !!cardData?.shift?.cancelledAt;
  const isDraft = !!cardData?.shift?.isDraft;
  const isNightShift = cardData?.shift?.type === SHIFT_TYPES.NIGHT;

  const isBookingCancelled = cardData?.state === "CANCELLED";

  return (
    <GridCardWrapper
      isStream={!!cardData?.shift?.stream?.id}
      isNightShift={isNightShift}
      isShiftCancelled={isShiftCancelled}
      isBookingCancelled={isBookingCancelled}
      isDraft={isDraft}
      key={cardData?.shift?.id}
      onClick={() => onClick(cardData?.shift)}
    >
      <StyledMaskContainer>
        <Div fontSize="12px" textTransform="capitalize">
          {roleName}
        </Div>
        <Div fontSize="12px" fontWeight="500">
          {formatHours(cardData.shift)}
        </Div>
        <Div fontSize="12px" width="auto">
          {`${cardData?.shift?.venue?.name} (${cardData?.shift?.sourceAccount.accountName})`}
        </Div>
      </StyledMaskContainer>
    </GridCardWrapper>
  );
};

const mapStateToProps = s => ({
  showCancelledShifts: s.scheduler?.showCancelledShifts
});

export default connect(mapStateToProps)(GridCardContent);
