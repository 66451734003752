import { createSelector } from "reselect";
import { extractBriefingUniformText } from "../shared";
import * as utils from "../../utils";

// Map props<briefingTemplates> to createBriefingOptions
export const useBriefingTemplates = createSelector(
  [props => props.briefingTemplates],
  briefingTemplates => {
    return utils.createBriefingOptions(briefingTemplates);
  }
);

// Map props<uniformOptions> to uniformOptions
export const useUniformOptions = createSelector(
  [props => props.uniformOptions],
  uniformOptions => {
    return utils.createUniformOptions(uniformOptions, false);
  }
);

// Handle briefing select
export const useUpdateBriefing = createSelector(
  [props => props.handleShiftUpdate, props => props.shift],
  (handleShiftUpdate, shift) => (briefing, fixedLabel) => {
    handleShiftUpdate({
      briefing,
      privates: {
        ...shift.privates,
        fixedLabel
      }
    });
  }
);

// Map props to formTitle
export const getFormTitle = createSelector(
  [props => props.shift, props => props.uniformOptions],
  extractBriefingUniformText
);

// Map state to props<roleRateId, venueId>
export const mapStateToProps = ({
  calendar: {
    shiftForm: { shifts, shiftOpenIndex }
  }
}) => ({
  shift: shifts[shiftOpenIndex]
});
