import React, { useState } from "react";
import styled from "styled-components";
import { RotaDropdownHeader, RotaRadio } from "@teamrota/rota-design";
import { SORT_BY } from "~/src/consts";

const StyledDropdownsContainer = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  border: solid 1px ${({ theme }) => theme.neutral.light};
  background-color: ${({ theme }) => theme.white};
`;

const SortDropdown = ({ setSortBy, sortBy }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RotaDropdownHeader
      labelText={"Sort by..."}
      isOpen={isOpen}
      setIsOpen={() => setIsOpen(isOpen => !isOpen)}
    >
      <StyledDropdownsContainer>
        <RotaRadio
          name={SORT_BY.FIRSTNAME}
          label="First Name"
          checked={sortBy === SORT_BY.FIRSTNAME}
          onChange={e => setSortBy(e.target.name)}
        />

        <RotaRadio
          name={SORT_BY.LASTNAME}
          label="Last Name"
          checked={sortBy === SORT_BY.LASTNAME}
          onChange={e => setSortBy(e.target.name)}
        />

        <RotaRadio
          name={SORT_BY.MOST_SHIFTS_WORKED}
          label="Most Shifts"
          checked={sortBy === SORT_BY.MOST_SHIFTS_WORKED}
          onChange={e => setSortBy(e.target.name)}
        />

        <RotaRadio
          name={SORT_BY.LEAST_SHIFTS_WORKED}
          label="Least Shifts"
          checked={sortBy === SORT_BY.LEAST_SHIFTS_WORKED}
          onChange={e => setSortBy(e.target.name)}
        />
      </StyledDropdownsContainer>
    </RotaDropdownHeader>
  );
};

export default SortDropdown;
