import React, { memo } from "react";
import moment from "moment-timezone";
import { Role } from "@teamrota/authlib";
import {
  RotaButtonNew,
  RotaCalendar,
  RotaSearchInput,
  RotaStats
} from "@teamrota/rota-design";

import HasRole from "~/src/containers/has-role";
import Icon from "~/src/components/icon";
import IconLink from "~/src/containers/payroll/components/IconLink";

// scheduler references
import PublishContainer from "~/src/containers/scheduler/PublishContainer";
import {
  Heading,
  Row,
  StyledToolBarLeft,
  StyledToolBarRight,
  PublishContainerWrapper,
  StyledFilterContainer,
  ButtonsWrapper
} from "./calendar-header.styles";

import FilterDropdown from "./FilterDropdown/filter-dropdown";
import SortDropdown from "./SortDropdown/sort-dropdown";

const CalendarHeader = ({
  setStartDate,
  startDate,
  setIsShiftCreateModalOpen,
  countOfDraftShifts,
  draftShiftIds,
  schedulesLoading,
  refetchSchedules,
  forceRefetchSchedules,
  handlePartnerSelect,
  partners,
  setRoleIds,
  setVenueIds,
  setHomeVenueIds,
  isShowCancelledShifts,
  setIsShowCancelledShifts,
  isShowCancelledBookings,
  setIsShowCancelledBookings,
  setSelectedUserIds,
  setShiftTypes,
  setShiftSources,
  setCreatedAfter,
  setAvailabilityWeekDays,
  schedule,
  setSearchTerm,
  setSortBy,
  sortBy
}) => {
  const totalMembersCount = schedule?.totalMembersCount || 0;

  const totalScheduledBookingsCount =
    schedule?.totalScheduledBookingsCount || 0;

  const totalUnassignedBookingsCount =
    schedule?.totalUnassignedBookingsCount || 0;

  const totalBookings =
    totalScheduledBookingsCount + totalUnassignedBookingsCount;

  const fulfillment = (totalBookings > 0
    ? (totalScheduledBookingsCount / totalBookings) * 100
    : 100
  ).toFixed(1);

  const stats = [
    { label: "members", value: totalMembersCount },
    { label: "bookings", value: totalBookings },
    { label: "bookings filled", value: totalScheduledBookingsCount },
    { label: "unfilled bookings", value: totalUnassignedBookingsCount },
    { label: "fulfilment", value: `${fulfillment}%` }
  ];

  return (
    <Heading>
      <Row withBorder>
        <RotaStats stats={stats} />
        <ButtonsWrapper>
          <HasRole role={Role.SHIFTS_CREATE}>
            <RotaButtonNew
              variant="outlined"
              onClick={() => setIsShiftCreateModalOpen(true)}
            >
              Create Shift
            </RotaButtonNew>
          </HasRole>
          <PublishContainerWrapper>
            <PublishContainer
              countOfDraftShifts={countOfDraftShifts}
              draftShiftIds={draftShiftIds}
              refetchSchedules={refetchSchedules}
            />
          </PublishContainerWrapper>
        </ButtonsWrapper>
      </Row>
      <Row>
        <StyledToolBarLeft>
          <RotaSearchInput onChange={value => setSearchTerm(value)} />
          <SortDropdown setSortBy={setSortBy} sortBy={sortBy} />
          <StyledFilterContainer>
            <FilterDropdown
              partners={partners}
              handlePartnerSelect={handlePartnerSelect}
              setRoleIds={setRoleIds}
              setVenueIds={setVenueIds}
              setHomeVenueIds={setHomeVenueIds}
              isShowCancelledShifts={isShowCancelledShifts}
              setIsShowCancelledShifts={setIsShowCancelledShifts}
              isShowCancelledBookings={isShowCancelledBookings}
              setIsShowCancelledBookings={setIsShowCancelledBookings}
              setSelectedUserIds={setSelectedUserIds}
              setShiftTypes={setShiftTypes}
              setShiftSources={setShiftSources}
              setCreatedAfter={setCreatedAfter}
              setAvailabilityWeekDays={setAvailabilityWeekDays}
            />
          </StyledFilterContainer>
        </StyledToolBarLeft>

        <StyledToolBarRight>
          <RotaCalendar
            value={startDate?.toDate()}
            onChangeDate={date => {
              setStartDate(moment(date).startOf("day"));
            }}
          />

          {forceRefetchSchedules && (
            <IconLink
              name="REFRESH"
              size={25}
              isButton
              isDisabled={schedulesLoading}
              color={Icon.colors.MAIN_ORANGE}
              onClick={() => forceRefetchSchedules()}
            />
          )}
        </StyledToolBarRight>
      </Row>
    </Heading>
  );
};

export default memo(CalendarHeader);
