import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";

import useAuth from "~/src/auth/hooks/use-auth";
import CalendarHeader from "~/src/containers/scheduler/CalendarHeader/calendar-header";
import ManageShiftsSideBar from "~/src/containers/requester-calendar/manage-shifts-sidebar";
import ShiftCreateModal from "./ShiftCreateModal";
import ShiftEditModal from "~/src/containers/requester-calendar/shift-modal";
import SchedulerGrid from "./SchedulerGrid/scheduler-grid";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { useSchedules } from "~/src/graphql/queries/use-schedules";

import {
  postShift,
  cancelPostShift,
  clearBonusState,
  toggleCancelledShifts,
  countDraftShifts
} from "./reducer";

import asyncConfirm from "~/src/utils/async-confirm";

import { getTargetAccount } from "./utils";

import {
  CalendarContainer,
  GridWrapper,
  Wrapper
} from "~/src/containers/scheduler/scheduler.styles";

const Scheduler = ({
  user,
  policy,
  postShift,
  toggleCancelledShifts,
  countDraftShifts,
  showCancelledShifts,
  countOfDraftShifts,
  draftShiftIds
}) => {
  const auth = useAuth();

  const defaultStartDate = moment().startOf("isoWeek");
  const [startDate, setStartDate] = useState(defaultStartDate);
  const startTime = startDate.format();
  const endTime = startDate
    .clone()
    .add(6, "days")
    .startOf("day")
    .format();

  const [isShiftCreateModalOpen, setIsShiftCreateModalOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [homeVenueIds, setHomeVenueIds] = useState([]);
  const [roleIds, setRoleIds] = useState();
  const [venueIds, setVenueIds] = useState();
  const [sortBy, setSortBy] = useState("FIRSTNAME");
  const [searchTerm, setSearchTerm] = useState("");
  const [editShiftModalShiftId, setEditShiftModalShiftId] = useState(null);

  const [selectedDayIndex, setSelectedDayIndex] = useState(null);

  const targetAccountId = getTargetAccount(user?.account?.requesterConnections);

  const getSchedulesProps = {
    startTime,
    endTime,
    homeVenueIds,
    roleIds,
    sortBy,
    searchTerm
  };

  const { scheduleData, refetchSchedules, loadMoreSchedules } = useSchedules(
    getSchedulesProps
  );

  const handleOpenShiftCreateModal = ({ startTime, scheduledMember }) => {
    if (!auth.hasRole(Role.SHIFTS_CREATE)) return;
    const now = moment();
    const hour = now.hour();
    const minute = now.minute();
    const updatedStartTime = moment(startTime).set({
      hour: parseInt(hour, 10),
      minute: parseInt(minute + 15, 10)
    });

    postShift({
      defaultBreakMinutes: user?.account?.defaultBreakMinutes,
      ...(!!startTime && {
        startTime: updatedStartTime,
        endTime: moment(updatedStartTime)
          .clone()
          .endOf("day")
      }),
      ...(!!scheduledMember && {
        assignedMemberIds: [scheduledMember?.member?.id],
        assignedMemberName: `${scheduledMember?.member?.firstName} ${scheduledMember?.member?.lastName}`,
        assignedMemberRoleIds: scheduledMember?.member?.roles,
        memberType: scheduledMember?.member?.memberType,
        isGridSelectedShift: true
      })
    });

    setIsShiftCreateModalOpen(true);
  };

  const handleClosePopUp = async () => {
    if (
      await asyncConfirm("Do you want to cancel this schedule?", {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Yes, cancel this schedule"
      })
    ) {
      setIsShiftCreateModalOpen(false);
    }
  };

  return (
    <CalendarContainer>
      <CalendarHeader
        setStartDate={setStartDate}
        startTime={startTime}
        endTime={endTime}
        handleShowCancelShifts={() => toggleCancelledShifts()}
        shouldShowCancelled={showCancelledShifts}
        onToggleSideBar={() => setIsSideBarOpen(!isSideBarOpen)}
        setIsShiftCreateModalOpen={handleOpenShiftCreateModal}
        handleCountOfDraftShifts={() => countDraftShifts()}
        countOfDraftShifts={countOfDraftShifts}
        draftShiftIds={draftShiftIds}
        refetchSchedules={refetchSchedules}
      />

      <Wrapper>
        <GridWrapper>
          <SchedulerGrid
            schedule={scheduleData?.account?.schedule || {}}
            selectedDayIndex={selectedDayIndex}
            setSelectedDayIndex={setSelectedDayIndex}
            refetchSchedules={refetchSchedules}
            startDate={startDate}
            setHomeVenueIds={setHomeVenueIds}
            setRoleIds={setRoleIds}
            venueIds={venueIds}
            setVenueIds={setVenueIds}
            setSearchTerm={setSearchTerm}
            setSortBy={setSortBy}
            onPressGridCell={booking => handleOpenShiftCreateModal(booking)}
            onPressShiftCell={booking => {
              if (!auth.hasRole(Role.SHIFTS_EDIT)) return;
              setEditShiftModalShiftId(booking);
            }}
            totalMembersCount={
              scheduleData?.account?.schedule?.totalMembersCount
            }
            loadMore={loadMoreSchedules}
            isMemberAvailabilityEnabled={
              scheduleData?.account?.isMemberAvailabilityEnabled
            }
            {...getSchedulesProps}
          />
        </GridWrapper>
      </Wrapper>

      <ManageShiftsSideBar
        isOpen={isSideBarOpen}
        onToggleSideBar={() => setIsSideBarOpen(!isSideBarOpen)}
      />

      <ShiftCreateModal
        onClose={handleClosePopUp}
        isOpen={isShiftCreateModalOpen}
        targetAccountId={targetAccountId}
        policy={policy}
        user={user}
        refetchSchedules={refetchSchedules}
        setIsShiftCreateModalOpen={setIsShiftCreateModalOpen}
      />

      <ShiftEditModal
        policy={policy}
        shiftId={editShiftModalShiftId}
        onClose={() => {
          setEditShiftModalShiftId(null);
          refetchSchedules();
        }}
        targetAccountId={targetAccountId}
        {...getSchedulesProps}
      />
    </CalendarContainer>
  );
};

const mapStateToProps = s => ({
  selectedMemberId: s.pools?.selectedMemberId,
  isGridSelectedShift: s.scheduler?.isGridSelectedShift,
  showCancelledShifts: s.scheduler?.showCancelledShifts,
  countOfDraftShifts: s.scheduler?.countOfDraftShifts,
  draftShiftIds: s.scheduler?.draftShiftIds
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postShift,
      clearBonusState,
      cancelPostShift,
      toggleCancelledShifts,
      countDraftShifts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(getProfile(Scheduler));
