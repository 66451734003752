import React, { useEffect, useRef, useState, createContext } from "react";
import { useTheme } from "styled-components";
import { connect } from "react-redux";

import { useQuery } from "@apollo/client";
import { typography } from "@teamrota/rota-design";

import useAuth from "~/src/auth/hooks/use-auth";
import { checkErrors } from "~/src/utils/errors";
import * as actions from "~/src/containers/pools/reducer";
import MemberPhoto from "~/src/components/member-photo";
import DraggableMember from "~/src/components/StaffZonesPanel/components/MemberCarousel/components/DraggableMember";
import LoadSpinner from "~/src/components/StaffZonesPanel/components/MemberCarousel/components/LoadingSpinner";
import { ARROW_WIDTH } from "~/src/components/StaffZonesPanel/components/MemberCarousel/components/Arrow/styles";
import {
  LeftArrow,
  RightArrow
} from "~/src/components/StaffZonesPanel/components/MemberCarousel/components/Arrow";
import {
  CARD_MARGIN,
  CARD_WIDTH
} from "~/src/components/StaffZonesPanel/components/MemberCarousel/components/DraggableMember/styles";
import { GET_MEMBER_LAZY } from "~/src/components/StaffZonesPanel/graphql/queries/get-member-lazy";
import { useAccountMemberAvailability } from "~/src/components/StaffZonesPanel/graphql/queries/get-account-member-availability.js";

import { getLabel } from "./labels";
import { StyledWrapMemberCarousel, StyledSideScroll } from "./styles";

const { StyledSubtitle } = typography;

const CARD_TOTAL_WIDTH = CARD_WIDTH + CARD_MARGIN;

const MemberCarouselContext = createContext();

export const MemberCarousel = ({
  isShowPlaceholder,
  hasMore,
  onLoadMore,
  isLoading,
  isEmpty,
  isUsing,
  isFiltered,
  selectedGroupId, // pass in to reset scroll to left when changed
  children
}) => {
  const theme = useTheme();
  const [isShowLeftArrow, setIsShowLeftArrow] = useState(false);
  const [isShowRightArrow, setIsShowRightArrow] = useState(false);

  const [viewport, setViewport] = useState(null);

  const scrollRef = useRef();

  let placeholder = null;
  if (isShowPlaceholder && !isLoading && isEmpty) {
    if (isFiltered) {
      placeholder = {
        title: "No matching members",
        description: "Please try some other search terms or filters"
      };
    } else if (isUsing) {
      placeholder = {
        title: "Everyone already added",
        description:
          "All staff from this group are assigned/requested for this shift"
      };
    } else {
      placeholder = {
        title: "The group is empty",
        description: "Navigate to 'Pools' and add members to it"
      };
    }
  }

  const scrollLeft = () => {
    if (scrollRef.current && viewport !== null) {
      const maxCards = Math.floor(
        (viewport.width - CARD_TOTAL_WIDTH * 0.5) / CARD_TOTAL_WIDTH
      );

      const scrollBy =
        maxCards * CARD_TOTAL_WIDTH - (isShowRightArrow ? 0 : ARROW_WIDTH);

      const scrollTo = scrollRef.current.scrollLeft - scrollBy;

      scrollRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current && viewport !== null) {
      const maxCards = Math.floor(
        (viewport.width - CARD_TOTAL_WIDTH * 0.5) / CARD_TOTAL_WIDTH
      );

      const scrollBy =
        maxCards * CARD_TOTAL_WIDTH - (isShowLeftArrow ? 0 : ARROW_WIDTH);

      const scrollTo = scrollRef.current.scrollLeft + scrollBy;

      scrollRef.current.scrollTo({ left: scrollTo, behavior: "smooth" });
    }
  };

  const resetViewport = () => {
    if (scrollRef.current) {
      const scrollRemaining =
        scrollRef.current.scrollWidth -
        scrollRef.current.offsetWidth -
        scrollRef.current.scrollLeft;

      setIsShowRightArrow(scrollRemaining > 5 || hasMore);
      setIsShowLeftArrow(scrollRef.current.scrollLeft > 5);

      const newViewport = {
        left:
          scrollRef.current.scrollLeft + (isShowLeftArrow ? ARROW_WIDTH : 0),
        width:
          scrollRef.current.offsetWidth -
          (isShowLeftArrow ? ARROW_WIDTH : 0) -
          (isShowRightArrow ? ARROW_WIDTH : 0),
        top: 0,
        height: scrollRef.current.offsetHeight
      };

      if (scrollRemaining < newViewport.width && hasMore) {
        onLoadMore();
      }

      setViewport(newViewport);
    }
  };

  useEffect(() => {
    resetViewport();
  }, [
    isLoading,
    isEmpty,
    selectedGroupId,
    isShowLeftArrow,
    isShowRightArrow,
    scrollRef?.current?.scrollWidth
  ]);

  useEffect(() => {
    scrollRef.current?.scrollTo({ left: 0 });
  }, [selectedGroupId]);

  return (
    <StyledWrapMemberCarousel>
      {isLoading && <LoadSpinner />}

      {placeholder && (
        <StyledSubtitle color={theme.neutral.main}>
          {placeholder.title}
          <br />
          {placeholder.description}
        </StyledSubtitle>
      )}

      {!placeholder && !isLoading && (
        <>
          {isShowLeftArrow && <LeftArrow onClick={scrollLeft} />}

          <StyledSideScroll ref={scrollRef} onScroll={resetViewport}>
            <MemberCarouselContext.Provider value={{ viewport }}>
              {children}
            </MemberCarouselContext.Provider>
          </StyledSideScroll>

          {isShowRightArrow && <RightArrow onClick={scrollRight} />}
        </>
      )}
    </StyledWrapMemberCarousel>
  );
};

const MemberCard = ({
  memberInfo,
  isDisabled,
  isDragDisabled,
  onDragEnd,
  onRemove,
  dispatch,
  ...extraProps
}) => {
  const auth = useAuth();

  const { loading, data } = checkErrors(
    useQuery(GET_MEMBER_LAZY, {
      skip: !memberInfo.lazy,
      variables: auth.addVals(GET_MEMBER_LAZY, memberInfo),
      fetchPolicy: "cache-first"
    })
  );

  const { isMemberAvailabilityEnabled } = useAccountMemberAvailability();

  if (loading) {
    return false;
  }

  const member = memberInfo.lazy ? data.account.member : memberInfo;

  const label = getLabel({
    ...member,
    ...extraProps,
    isMemberAvailabilityEnabled
  });

  return (
    <DraggableMember
      id={member.id}
      key={member.id}
      name={`${member.firstName} ${member.lastName}`}
      isDisabled={isDisabled}
      isDragDisabled={isDragDisabled}
      onDragEnd={onDragEnd}
      onRemove={onRemove}
      showMemberModal={() => dispatch(actions.showMemberDetails(member.id))}
    >
      <MemberPhoto
        src={member.photo}
        poolType={member.relationship?.poolType}
        status={member.state}
        isAsleep={member.isAsleep}
        errorLabelColor={label?.color}
        errorLabel={label?.text}
        isShowTooltip={false}
      />
    </DraggableMember>
  );
};

export const MembersSet = connect()(({ members, ...props }) =>
  members?.map(memberInfo => (
    <MemberCard key={memberInfo.id} memberInfo={memberInfo} {...props} />
  ))
);

export const MembersGroup = ({
  selectedGroup,
  members,
  isFiltered,
  onDragEnd
}) => {
  const theme = useTheme();
  return (
    <DraggableMember
      key={selectedGroup.id}
      id={0}
      memberIds={members && members.map(({ id }) => id)}
      name={isFiltered ? "Matching Members" : "Whole Group"}
      onDragEnd={onDragEnd}
    >
      <MemberPhoto
        isBigLabel
        errorLabelColor={theme.primary.main}
        errorLabel={members.length}
        isShowTooltip={false}
      />
    </DraggableMember>
  );
};

export default MemberCarousel;
