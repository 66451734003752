import React, { memo, useState } from "react";

import Icon from "~/src/components/icon";
import { Container, Button, Text } from "./styles";
import { COLORS } from "~/src/styles/config.style";

interface IconLinkProps {
  name: string;
  text?: string;
  color: string;
  hoverColor?: string;
  size: number;
  isButton?: boolean;
  isSpaced?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}

const IconLink: React.FC<IconLinkProps> = ({
  name,
  text,
  color,
  size,
  isButton = false,
  isSpaced = false,
  isDisabled = false,
  onClick
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const currentColor =
    isButton && isHovering
      ? Icon.colors.WHITE
      : isDisabled
      ? Icon.colors.WARM_GREY
      : color;

  const backgroundColorCode = COLORS[color as keyof typeof COLORS];

  return (
    <Container
      isSpaced={isSpaced}
      onPointerOver={() => !isHovering && setIsHovering(!isDisabled && true)}
      onPointerLeave={() => isHovering && setIsHovering(false)}
      onClick={() => !isDisabled && onClick()}
    >
      <Button
        size={size + (isButton ? 12 : 0)}
        hasText={!!text}
        isDisabled={isDisabled}
        isButton
        isHovering={isButton && isHovering}
        backgroundColor={backgroundColorCode}
      >
        <Icon
          name={Icon.names[name.toUpperCase()]}
          size={size}
          color={currentColor}
          onClick={() => !isDisabled && onClick()}
        />
      </Button>

      {text && (
        <Text isDisabled={isDisabled} size={size}>
          {text}
        </Text>
      )}
    </Container>
  );
};

export default memo(IconLink);
