import React from "react";
import moment from "moment-timezone";

import Icon from "~/src/components/icon";

import {
  GridCardWrapper,
  StyledMaskContainer,
  Div,
  IconWrapper
} from "~/src/containers/scheduler/BusyGridCard/busy-grid-card.styles";

const BusyGridCard = ({ busy, onClick }) => {
  return (
    <GridCardWrapper onClick={onClick}>
      <StyledMaskContainer>
        <Div fontSize="12px" textTransform="capitalize">
          Busy
        </Div>
        <Div fontSize="12px" fontWeight="500">
          {busy.map(({ startTime, endTime }, i) => (
            <>
              {i > 0 ? ", " : ""}
              {moment(startTime).format("HH:mm")} -{" "}
              {moment(endTime).format("HH:mm")}
            </>
          ))}
        </Div>
        <Div fontSize="12px" width="auto">
          N/A
        </Div>
      </StyledMaskContainer>

      <IconWrapper>
        <Icon
          name={Icon.names.PLUS}
          color={Icon.colors.SILVER}
          size={Icon.sizes.SMALL}
        />
      </IconWrapper>
    </GridCardWrapper>
  );
};

export default BusyGridCard;
