import React from "react";
import { icon as RotaIcon, colors } from "@teamrota/rota-design";
import {
  StyledHoursWorkedContainer,
  StyledHoursWorkedText,
  StyledInfoGroup
} from "./hours-worked.styles";
import Icon from "~/src/components/icon";

const { ClockIcon } = RotaIcon;

const HoursWorked = ({
  isLimitedHours,
  limitedHours,
  hoursCompleted,
  shiftsWorked
}) => {
  return (
    <StyledHoursWorkedContainer>
      <StyledInfoGroup>
        <ClockIcon size={16} color={colors.rotaOrange} />
        <StyledHoursWorkedText>
          {hoursCompleted || 0}
          {isLimitedHours && ` / ${limitedHours} `}
        </StyledHoursWorkedText>
      </StyledInfoGroup>
      <StyledInfoGroup>
        <Icon
          name={Icon.names.BRIEFCASE}
          color={Icon.colors.WHITE}
          size={Icon.sizes.SMALL}
        />
        <StyledHoursWorkedText>{shiftsWorked || 0}</StyledHoursWorkedText>
      </StyledInfoGroup>
    </StyledHoursWorkedContainer>
  );
};

export default HoursWorked;
