import { colors } from "@teamrota/rota-design";
import styled from "styled-components";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledOpenShiftContainer = styled.div`
  width: 20%;
  min-width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.2;

  &.faded {
    opacity: 0.2;

    animation: fade-out 0.25s ease-out forwards;

    @keyframes fade-out {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0.2;
      }
    }
  }

  &:not(.faded) {
    opacity: 1;

    animation: fade-in 0.25s ease-out forwards;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export const GridCardWrapper = styled.div`
  width: 95%;
  white-space: nowrap;
  height: 50px;
  background: ${({
    isCancelled,
    hasCancelledBookings,
    isDraft,
    isNightShift,
    isStream
  }) =>
    isCancelled
      ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#ffcccc 10px 20px)"
      : hasCancelledBookings
      ? "repeating-linear-gradient(-60deg,transparent 0px 10px,#eebb0055 10px 20px)"
      : isDraft
      ? "white"
      : isStream && isNightShift
      ? "#052d63"
      : isStream
      ? "#A3CAFF"
      : isNightShift
      ? "#313131"
      : colors.lightOrange};
  border: ${({ isDraft }) => (isDraft ? "2px dashed" : "2px solid")};
  border-color: ${({ isStream, isCancelled, hasCancelledBookings }) =>
    isCancelled
      ? "#ff0000"
      : hasCancelledBookings
      ? "#eebb00"
      : isStream
      ? "#2f84f7"
      : "#ff9300"};
  border-radius: 5px;
  padding: ${({ padding }) => padding || "5px"};
  line-height: ${({ lineHeight }) => lineHeight};
  overflow: hidden;
  margin: ${({ margin }) => margin || "0 1px"};
  cursor: pointer;
  color: ${({ isNightShift, isDraft, isCancelled, hasCancelledBookings }) =>
    isNightShift && !isDraft && !isCancelled && !hasCancelledBookings
      ? "white"
      : "black"};
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "all")};
  cursor: ${({ isLoading }) => (isLoading ? "not-allowed" : "pointer")};
`;

export const Div = styled.div`
  display: flex;
  width: ${({ width }) => width};
  justify-content: center;
  align-items: center;
  line-height: ${({ lineHeight }) => lineHeight};
  font-size: ${({ fontSize }) => fontSize || STYLE_CONSTS.FONT_SMALL};
  font-weight: ${({ fontWeight }) => fontWeight || STYLE_CONSTS.FONT_WEIGHT};
  text-transform: ${({ textTransform }) => textTransform};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-overflow: ellipsis;
  white-space: ${({ wrap }) => wrap || "nowrap"};
`;
