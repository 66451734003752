import React from "react";
import ReactTooltip from "react-tooltip";

import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";
import { keyMirror } from "~/src/utils";
import { StyledSVG } from "./icon.styles";
import ICONS from "./icons";

export const ICON_NAMES = keyMirror(ICONS);

const SIZES = {
  XXXLARGE: STYLE_CONSTS.ICON_XXXLARGE,
  XXLARGE: STYLE_CONSTS.ICON_XXLARGE,
  XLARGE: STYLE_CONSTS.ICON_XLARGE,
  LARGE: STYLE_CONSTS.ICON_LARGE,
  MEDIUM: STYLE_CONSTS.ICON_MEDIUM,
  SMALLMID: STYLE_CONSTS.ICON_SMALLMID,
  SMALL: STYLE_CONSTS.ICON_SMALL,
  XSMALL: STYLE_CONSTS.ICON_XSMALL,
  XXSMALL: STYLE_CONSTS.ICON_XXSMALL
};

const Icon = ({
  name,
  color,
  size,
  className = undefined,
  isNotCentered = false,
  onClick,
  tooltip = null
}) => {
  const tooltipId = tooltip && `tooltip-${Math.random()}`;

  return (
    <>
      {tooltip && (
        <ReactTooltip id={tooltipId} effect="solid">
          {tooltip}
        </ReactTooltip>
      )}
      <StyledSVG
        onClick={onClick}
        className={className}
        svg={ICONS[name]}
        cleanup
        fill={color ? COLORS[color] || color : COLORS.MAIN_ORANGE}
        // NOTE: PropType.string!
        height={`${getIconHeight(size)}px`}
        width={`${getIconHeight(size)}px`}
        $isNotCentered={isNotCentered}
      />
    </>
  );
};

const colors = keyMirror(COLORS);
const sizes = keyMirror(SIZES);
Icon.names = ICON_NAMES;
Icon.colors = colors;
Icon.sizes = sizes;

function getIconHeight(size) {
  if (!size) return STYLE_CONSTS.ICON_MEDIUM;
  if (!isNaN(parseInt(size, 10))) return parseInt(size, 10);
  if (typeof size === "string") return SIZES[size.toUpperCase()];
  return STYLE_CONSTS.ICON_MEDIUM;
}

export default Icon;
