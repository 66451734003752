import React, { useContext, useEffect } from "react";
import { RotaChip } from "@teamrota/rota-design";

import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";
import { FocusShiftContext } from "~/src/containers/provide-schedule/focus-shift-context";

import IconLink from "~/src/containers/payroll/components/IconLink";
import Icon from "~/src/components/icon";

import {
  StyledFocusContainer,
  StyledPrompt,
  StyledFilter,
  StyledGroup
} from "./focus-chips.styles";

function FocusChips({ user }) {
  const {
    focusShift,
    isVenueFilter,
    setIsVenueFilter,
    isRoleFilter,
    setIsRoleFilter,
    isAvailabilityFilter,
    setIsAvailabilityFilter,
    isTagsFilter,
    setIsTagsFilter,
    isNotUnsuitableFilter,
    setIsNotUnsuitableFilter,
    isNotExcludedFilter,
    setIsNotExcludedFilter
  } = useContext(FocusShiftContext);

  const isMemberAvailabilityEnabled =
    user?.account?.isMemberAvailabilityEnabled;

  useEffect(() => {
    if (!isMemberAvailabilityEnabled) {
      setIsAvailabilityFilter(false);
    }
  }, [focusShift, isMemberAvailabilityEnabled]);

  if (focusShift === null) {
    return "";
  }

  const showGroupMatching =
    isRoleFilter || isVenueFilter || isAvailabilityFilter || isTagsFilter;

  const showGroupWhoAre = isNotUnsuitableFilter || isNotExcludedFilter;

  return (
    <StyledFocusContainer>
      {(showGroupMatching || showGroupWhoAre) && (
        <StyledPrompt>Show only members</StyledPrompt>
      )}
      {showGroupMatching && (
        <StyledGroup className="group">with correct </StyledGroup>
      )}
      {isRoleFilter && (
        <RotaChip
          chipText={"Role"}
          onClose={() => setIsRoleFilter(false)}
          onClick={() => {}}
        />
      )}
      {isVenueFilter && (
        <RotaChip
          chipText={"Venue"}
          onClose={() => setIsVenueFilter(false)}
          onClick={() => {}}
        />
      )}
      {isAvailabilityFilter && (
        <RotaChip
          chipText={"Availability"}
          onClose={() => setIsAvailabilityFilter(false)}
          onClick={() => {}}
        />
      )}
      {isTagsFilter && (
        <RotaChip
          chipText={"Tags"}
          onClose={() => setIsTagsFilter(false)}
          onClick={() => {}}
        />
      )}
      {showGroupWhoAre && <StyledGroup className="group">who are </StyledGroup>}
      {isNotUnsuitableFilter && (
        <RotaChip
          chipText={"Not Unsuitable"}
          onClose={() => setIsNotUnsuitableFilter(false)}
          onClick={() => {}}
        />
      )}
      {isNotExcludedFilter && (
        <RotaChip
          chipText={"Not Excluded"}
          onClose={() => setIsNotExcludedFilter(false)}
          onClick={() => {}}
        />
      )}

      {!showGroupMatching && !showGroupWhoAre && (
        <StyledFilter>Show all</StyledFilter>
      )}

      <IconLink
        name="CLEAR"
        size={25}
        isButton
        color={Icon.colors.MAIN_ORANGE}
        onClick={() => {
          setIsRoleFilter(true);
          setIsVenueFilter(true);

          if (isMemberAvailabilityEnabled) {
            setIsAvailabilityFilter(true);
          }

          setIsTagsFilter(true);
          setIsNotUnsuitableFilter(true);
          setIsNotExcludedFilter(true);
        }}
      />
    </StyledFocusContainer>
  );
}

export default getProfile(FocusChips);
