import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledScheduleCells = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledMaskContainer = styled.div`
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0));
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

export const IconWrapper = styled.span`
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Div = styled.div`
  display: flex;
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: center;
  line-height: ${({ lineHeight }) => lineHeight};
  font-size: ${({ fontSize }) => fontSize || STYLE_CONSTS.FONT};
  font-weight: ${({ fontWeight }) => fontWeight || STYLE_CONSTS.FONT_WEIGHT};
  text-transform: ${({ textTransform }) => textTransform};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  white-space: ${({ wrap }) => wrap || "nowrap"};
  max-width: 10px;
  overflow: visible;
`;

export const GridCardWrapper = styled.div`
  width: 100%;
  white-space: nowrap;
  height: 50px;

  background: repeating-linear-gradient(
    -60deg,
    transparent 0px 10px,
    ${COLORS.GREY_MEDIUM} 10px 20px
  );

  border: solid 2px ${COLORS.GREY_DARK};

  border-radius: 5px;
  padding: 5px;
  line-height: ${({ lineHeight }) => lineHeight};
  overflow-x: hidden;
  overflow-y: ${({ scrollable }) => scrollable || "hidden"};
  margin: 0 1px;
  color: black;
  cursor: pointer;
  line-height: 12px;

  &:hover {
    background: white;
    border: solid 2px ${COLORS.SILVER};
  }

  &:hover ${StyledMaskContainer} {
    display: none;
  }

  &:hover ${IconWrapper} {
    display: flex;
  }
`;
