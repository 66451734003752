import { gql } from "@apollo/client";
import { graphql } from "@apollo/client/react/hoc";
import { flow } from "lodash";
import { groupBy, merge } from "lodash/fp";

import { addAuthVars } from "@teamrota/authlib";

import { withAuth } from "~/src/auth/hoc";
import { handleErrors } from "~/src/utils/errors";

import { BOOKING_STATES } from "~/src/consts";

const groupShiftBookings = shift => {
  if (shift) {
    const MEMBER_GRID_BOOKINGS = {
      [BOOKING_STATES.ACCEPTED]: [],
      [BOOKING_STATES.CANCELLED]: [],
      [BOOKING_STATES.APPLIED]: [],
      [BOOKING_STATES.WAITING_LIST]: [],
      [BOOKING_STATES.PARTNER_DECLINED]: []
    };

    // Only show app cancelled bookings if there are any
    const appCancelledBookings = shift.bookings.data.filter(
      booking => booking.state === BOOKING_STATES.APP_CANCELLED
    );
    if (appCancelledBookings.length > 0) {
      MEMBER_GRID_BOOKINGS[BOOKING_STATES.APP_CANCELLED] = [];
    }

    return {
      ...shift,
      bookings: merge(
        MEMBER_GRID_BOOKINGS,
        groupBy("state", shift.bookings.data)
      )
    };
  }
  return {};
};

export const GET_SHIFT_GROUP = addAuthVars(gql`
  query getShiftDetails(
    $id: ID!
    $sourceAccountId: ID
    $view: BookingViewType
  ) {
    account {
      id
      shift(id: $id) {
        id
        name
        overbookPercentage
        startTime
        endTime
        originalStartTime
        originalEndTime
        numberRequested
        briefing
        identifier
        postedAt
        isDraft
        isDraftDeleted
        bonuses(isActive: true) {
          id
          type
          amount
          period
        }
        totalCostBonusesAndRewards(predicted: true, type: "bonus")
        type
        policy {
          id
          minimumShiftLength
          minimumShiftPercentage
          cancellationPeriod
        }
        sourceAccount {
          id
        }
        targetAccount {
          id
          logo
          accountName
        }
        uniform {
          items
        }
        tags {
          id
          name
        }
        cancelledAt
        venue {
          id
          name
        }
        roleRate {
          id
          roleName

          ... on roleRate {
            chargeRate
          }
        }
        isPartnerManaged
        isRestricted: skipAlgoShift
        isShiftRebook
        delayHoursAlgoShift
        algoSkew: algoMeta {
          algoSkew
        }
        stream {
          id
          sourceId
          account {
            id
            source
            name
            url
          }
        }
        cancelledBookings: bookings(
          stateEquals: CANCELLED
          offset: 0
          limit: 100
          hasReplacementBookingId: false
        ) {
          totalResults
        }
        requested: bookings(
          stateEquals: MATCHED
          bucketReasonIn: REQUESTED
          offset: 0
          limit: 100
        ) {
          data {
            id
            bookingState {
              id
              startFinal
              endFinal
              breakFinal
            }
            member {
              id
              photo
              firstName
              lastName
              rating
              shouldShowInGroup
              isQualifiedWithRole(shiftId: $id)
              bookingsCount(sourceAccountId: $sourceAccountId) {
                allPreviouslyAccepted
                previouslyAccepted
              }
              relationship {
                id
                poolType
              }
            }
          }
        }
        declined: bookings(
          stateEquals: DECLINED
          bucketReasonIn: REQUESTED
          offset: 0
          limit: 100
        ) {
          data {
            id
            bookingState {
              id
              startFinal
              endFinal
              breakFinal
              __typename
            }
            member {
              id
              photo
              firstName
              lastName
              rating
              shouldShowInGroup
              bookingsCount(sourceAccountId: $sourceAccountId) {
                allPreviouslyAccepted
                previouslyAccepted
                __typename
              }
              relationship {
                id
                poolType
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        bookings(
          stateIn: [
            ACCEPTED
            APPLIED
            WAITING_LIST
            PARTNER_DECLINED
            CANCELLED
            APP_CANCELLED
          ]
          offset: 0
          limit: 100
          view: $view
        ) {
          totalResults
          data {
            id
            state
            replacementBookingId
            replacementBooking {
              id
              member {
                id
                firstName
                lastName
              }
            }
            bookingState {
              id
              startFinal
              endFinal
              breakFinal
            }
            member {
              id
              photo
              firstName
              lastName
              rating
              shouldShowInGroup
              isQualifiedWithRole(shiftId: $id)
              bookingsCount(sourceAccountId: $sourceAccountId) {
                allPreviouslyAccepted
                previouslyAccepted
              }
              relationship {
                id
                poolType
              }
            }
          }
        }
      }
    }
  }
`);

export default flow(
  graphql(GET_SHIFT_GROUP, {
    options: ({ auth, ...ownProps }) => ({
      skip: !ownProps.shiftId,
      fetchPolicy: "network-only",
      variables: auth.addVals(GET_SHIFT_GROUP, {
        sourceAccountId: ownProps.user?.account?.id,
        id: (ownProps && ownProps.shiftId) || 0,
        bookingStates: [
          "ACCEPTED",
          "APPLIED",
          "WAITING_LIST",
          "PARTNER_DECLINED",
          "CANCELLED",
          "APP_CANCELLED"
        ],
        view: "CALENDAR"
      })
    }),
    props: handleErrors(({ data: { loading, account } }) => ({
      isLoadingShift: loading,
      shift: groupShiftBookings(account?.shift)
    }))
  }),
  withAuth
);
