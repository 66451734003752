import styled from "styled-components";
import Icon from "~/src/components/icon";
import { STYLE_CONSTS } from "~/src/styles/config.style";

export const StyledFocusContainer = styled.div`
  position: relative;
  height: 52px;

  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;

  background-color: ${({ theme }) => theme.white};

  font-weight: 500;

  padding: 5px 10px;
`;

export const StyledIcon = styled(Icon)`
  margin: 0 10px;
  cursor: pointer;
`;

export const StyledMenu = styled.div`
  position: absolute;
  display: ${({ isShow }) => (isShow ? "flex" : "none")};
  top: calc(100% - 15px);
  left: 0;
  width: calc(100% - 20px);
  margin: 10px;
  background-color: ${({ theme }) => theme.white};
  justify-content: left;
  flex-direction: column;
  border-radius: 10px;
  border: solid 3px ${({ theme }) => theme.primary.main};
  z-index: 50000;
`;

export const StyledDate = styled.div`
  font-size: ${STYLE_CONSTS.FONT_SMALL};
`;
