import React from "react";

import { StyledInfoBox } from "./stream-info.styles";

const SOURCE_NAMES = {
  allocate: "Allocate",
  softrecruit: "SoftRecruit"
};

const SOURCE_URLS = {
  allocate: stream => stream?.account?.url, // doesn't seem possible to link direct to a shift
  softrecruit: stream =>
    `https://www.softrecruit.com/sys/system/agencies/job_details_agencies.php?book_id=${stream.sourceId}`
};

export default function StreamInfo({ stream }) {
  const sourceName = stream?.account
    ? SOURCE_NAMES[stream?.account.source]
    : "Unknown";

  const sourceUrl = SOURCE_URLS[stream?.account.source]?.(stream) ?? "Unknown";

  return (
    <StyledInfoBox>
      <div>
        ShiftStream shift from &nbsp;
        <a href={stream?.account?.url} target="_blank">
          {sourceName} - {stream?.account?.name}
        </a>
      </div>
      <div>
        Source ID &nbsp;
        <a href={sourceUrl} target="_blank">
          {stream?.sourceId}
        </a>
      </div>
    </StyledInfoBox>
  );
}
