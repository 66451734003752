import React, { useContext, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { RotaButton } from "@teamrota/rota-design";

import { CANCELLATION_REASONS, SHIFT_STATES } from "~/src/consts";
import { formatDateMonthTime } from "~/src/utils/formatting";
import Icon from "~/src/components/icon";

import RequestAndAssignModal from "~/src/containers/provide/components/more-options-menu/request-assign-modal";
import { FocusShiftContext } from "~/src/containers/provide-schedule/focus-shift-context";
import IconLink from "~/src/containers/payroll/components/IconLink";
import asyncConfirm from "~/src/utils/async-confirm";
import { errorModal } from "~/src/utils/errors";

import { GET_SHIFT_BY_ID } from "./graphql/shift-by-id";
import updateShiftState from "./graphql/update-shift-state";

import {
  StyledFocusContainer,
  StyledMenu,
  StyledDate
} from "./focus-actions.styles";

const ICON_SIZE = 25;

function FocusActions({ onShiftDetails, updateShiftState }) {
  const { focusShift, setFocusShift, clearFocusShift } = useContext(
    FocusShiftContext
  );

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRequestAndAssignOpen, setIsRequestAndAssignOpen] = useState(false);

  const [fetchShift, { data, loading }] = useLazyQuery(GET_SHIFT_BY_ID);

  useEffect(() => {
    setIsMenuOpen(false);
    setIsRequestAndAssignOpen(false);
  }, [focusShift?.id]);

  const setNewShiftState = async (state, note) => {
    try {
      await updateShiftState(focusShift.id, state.toLowerCase(), note);

      fetchShift({
        variables: {
          id: focusShift?.id
        },
        fetchPolicy: "network-only"
      });
    } catch (e) {
      errorModal(e);
    }
  };

  const handleCancelShift = async () => {
    try {
      const ret = await asyncConfirm(
        "Are you sure you want to cancel this shift?",
        {
          confirmButtonText: "Cancel shift",
          falseButtonText: "Back",
          callback: () => {},
          dropDownOptions: Object.values(CANCELLATION_REASONS).map(reason => ({
            value: reason,
            label: reason
          }))
        }
      );

      if (ret) {
        await setNewShiftState(SHIFT_STATES.CANCELLED.toLowerCase(), ret);
      }
    } catch (e) {
      errorModal(e);
    }
  };

  useEffect(() => {
    // if this was a reload of this shift that was called
    // update fields we care about
    if (focusShift && data && data?.account?.shift?.id === focusShift?.id) {
      if (data.account.shift.cancelledAt) {
        clearFocusShift();
      } else {
        setFocusShift({ ...focusShift, ...data.account.shift }, true);
      }
    }
  }, [loading]);

  if (focusShift === null) {
    return "";
  }

  const isFinalised = !!focusShift.finalisedAt;
  const isOpened = !!focusShift.openedAt;
  const isCancelled = !!focusShift.cancelledAt;

  return (
    <>
      <StyledFocusContainer onPointerLeave={() => setIsMenuOpen(false)}>
        <StyledMenu isShow={isMenuOpen}>
          <IconLink
            name="ADD_MEMBER"
            text="Request / Assign"
            size={ICON_SIZE}
            color={Icon.colors.MAIN_ORANGE}
            isSpaced
            isButton
            onClick={() => {
              setIsRequestAndAssignOpen(true);
              setIsMenuOpen(false);
            }}
          />

          <IconLink
            name="VISIBLE"
            text={
              isOpened ? (
                <>
                  Opened
                  <StyledDate>
                    {formatDateMonthTime(focusShift.openedAt)}
                  </StyledDate>
                </>
              ) : (
                "Open shift"
              )
            }
            size={ICON_SIZE}
            color={Icon.colors.MAIN_ORANGE}
            isSpaced
            isButton
            isDisabled={isOpened}
            onClick={() => {
              setNewShiftState(SHIFT_STATES.VISIBLE);
              setIsMenuOpen(false);
            }}
          />

          <IconLink
            name="CHECK_CIRCLE"
            text={
              isFinalised ? (
                <>
                  Finalised
                  <StyledDate>
                    {formatDateMonthTime(focusShift.finalisedAt)}
                  </StyledDate>
                </>
              ) : (
                "Finalise shift"
              )
            }
            size={ICON_SIZE}
            color={Icon.colors.MAIN_ORANGE}
            isSpaced
            isButton
            isDisabled={isFinalised}
            onClick={() => {
              setNewShiftState(SHIFT_STATES.FINALISED);
              setIsMenuOpen(false);
            }}
          />

          <IconLink
            name="DELETE"
            text={
              isCancelled ? (
                <>
                  Cancelled
                  <StyledDate>
                    {formatDateMonthTime(focusShift.cancelledAt)}
                  </StyledDate>
                </>
              ) : (
                "Cancel shift"
              )
            }
            size={ICON_SIZE}
            color={Icon.colors.MAIN_ORANGE}
            isSpaced
            isButton
            isDisabled={isCancelled}
            onClick={() => {
              handleCancelShift();
              setIsMenuOpen(false);
            }}
          />
        </StyledMenu>

        <RotaButton onClick={() => onShiftDetails(focusShift, false)}>
          View Details
        </RotaButton>

        <RotaButton onClick={() => onShiftDetails(focusShift, true)}>
          Edit Details
        </RotaButton>

        <IconLink
          name="MORE"
          size={ICON_SIZE}
          isButton
          color={Icon.colors.MAIN_ORANGE}
          onClick={() => setIsMenuOpen(true)}
        />
      </StyledFocusContainer>

      {focusShift && isRequestAndAssignOpen && (
        <RequestAndAssignModal
          roleId={focusShift.roleRate.roleId}
          sourceAccountId={focusShift.sourceAccountId}
          shiftId={focusShift.id}
          venueId={focusShift.venue.id}
          roleRateId={focusShift.roleRate.id}
          refetch={() => {}}
          dates={{
            startTime: focusShift.startTime,
            endTime: focusShift.endTime
          }}
          shiftType={focusShift.shiftType}
          onClose={() => setIsRequestAndAssignOpen(false)}
        />
      )}
    </>
  );
}

export default updateShiftState(FocusActions);
